import axios from 'axios'

import { API_BASE_URL } from '../settings'


const previewData = {
  movie_yokai: {url: 'https://youkai-world02.com/article/48', title: '', img: ''},
  movie_mazica: {url: 'https://mazicaparty.com/news/1211/', title: '', img: ''},
  anime_shohari: {url: 'https://www.value-press.com/pressrelease/276551', title: '', img: ''},
}
const initialState = {
  parsing: false,
  movieYokai: previewData.movie_yokai,
  movieMazica: previewData.movie_mazica,
  animeShohari: previewData.anime_shohari,
}


export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    parsing(state) {
      return state.parsing
    },
    movieYokai(state) {
      return state.movieYokai
    },
    movieMazica(state) {
      return state.movieMazica
    },
    animeShohari(state) {
      return state.animeShohari
    },
  },
  mutations: {
    setPreviewData(state, data) {
      state.movieYokai = data.movie_yokai
      state.movieMazica = data.movie_mazica
      state.animeShohari = data.anime_shohari
    },
  },
  actions: {
    async getPreviewData({ commit, state }) {
      state.parsing = true
      const result = await axios.get(`${API_BASE_URL}/preview`, {
        params: {
          preview_data: previewData,
        }
      })
      commit('setPreviewData', result.data)
      state.parsing = false
    },
  },
}
