<template>
  <div>
    <div class="mainvisual">
      <div class="base-container">
        <div class="mv-title-box">
          <h1 class="mv-h1">
            <span v-html='$t("af_top_1")'></span>
          </h1>
          <h2 class="mv-h2">
            <span v-html='$t("af_top_2")'></span>
          </h2>
        </div>
      </div>
      <!-- 固定されるボタン -->
      <v-btn class="mv-cv-btn text-none white--text hide-md-down" id="show_btn" href="#g_form">
        <span v-html='$t("af_top_3")'></span>
      </v-btn>
      <video class="mv-video hide-md-down"
        src="https://storage.googleapis.com/anime_refiner_public_files/01_top/1_main-visual/AF_mainVisual_4mbps_10sec_4500kb.mp4"
        poster="https://storage.googleapis.com/anime_refiner_public_files/01_top/1_main-visual/mv_movie_poster.jpg" autoplay loop muted playsinline>
      </video>
    </div>
    <!-- フローティングするボタン -->
    <v-btn class="mv-cv-btn text-none white--text fixed-btn" :class="{'show-btn': showBtn }" href="#g_form">
      <span v-html='$t("af_top_3")'></span>
    </v-btn>
    <div class="what-is-ar">
      <div class="base-container">
        <div class="icon-container">
          <img class="ar-logo-img" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/2_what-is-ar/ar_logo.svg" alt="anime_refiner_logo">
        </div>
        <h2 class="whatis-heading">{{ $t("af_top_4") }}</h2>
        <p class="whatis-paragraph">
          <span v-html='$t("af_top_5")'></span>
        </p>
        <h3 class="whatis-subheading">{{ $t("af_top_6") }}</h3>
        <div class="appeal-movie-container">
          <iframe class="appeal-movie" src="https://www.youtube.com/embed/xt6UND-tPPg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <h3 class="whatis-subheading">{{ $t("af_top_7") }}<br></h3>
        <div class="comparisons">
          <div v-for="(comparison_pic, i) in comparison_pics" :key=i class="comparison-container">
            <div class="comparison-before">
              <div class="before-pic" :style="`background-image:url(${comparison_pic[0]})`"></div>
            </div>
            <div class="arrow-container">
              <v-img class="refine-arrow" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/anime_refine_arrow.svg"></v-img>
            </div>
            <div class="comparison-after">
              <div class="after-pic" :style="`background-image:url(${comparison_pic[1]})`"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 導入実績 -->
    <div class="case-study">
      <div class="base-container">
        <h2 class="case-study-heading">{{ $t("af_top_45") }}</h2>
        <!-- 映画 -->
        <div class="category-container">
          <h3 class="category-heading">{{ $t("af_top_46") }}</h3>
          <!-- 妖怪ウォッチ -->
          <div class="case-study-container">
            <p class="resolution-before-after">HD → 4K</p>
            <p class="summary" v-html='$t("af_top_48")'></p>
            <div v-if="parsing" class="loading-indicator-container">
              <v-progress-circular indeterminate color="#FF8538"></v-progress-circular>
            </div>
            <div v-else class="link-card">
              <a :href="movieYokai.url" target="_blank" rel="noopener">
                <div class="link-card-container">
                  <div class="link-card-img">
                    <img v-if="movieYokai.img" :src="movieYokai.img">
                    <template v-else>
                      <img>
                      <font-awesome-icon icon="image" class="icon"/>
                    </template>
                  </div>
                  <div class="link-card-text">
                    <font-awesome-icon icon="external-link-alt" class="icon"/>
                    <h4 v-if="movieYokai.title" class="page-title">{{ movieYokai.title }}</h4>
                    <h4 v-else class="page-title" v-html='$t("af_top_48")'></h4>
                    <span class="page-url">{{ movieYokai.url }}</span>
                  </div>
                </div>
              </a>
            </div>
            <v-divider></v-divider>
          </div>
          <!-- マジカパーティ -->
          <div class="case-study-container">
            <p class="resolution-before-after">HD → 4K</p>
            <p class="summary" v-html='$t("af_top_49")'></p>
            <div v-if="parsing" class="loading-indicator-container">
              <v-progress-circular indeterminate color="#FF8538"></v-progress-circular>
            </div>
            <div v-else class="link-card">
              <a :href="movieMazica.url" target="_blank" rel="noopener">
                <div class="link-card-container">
                  <div class="link-card-img">
                    <img v-if="movieMazica.img" :src="movieMazica.img">
                    <template v-else>
                      <img>
                      <font-awesome-icon icon="image" class="icon"/>
                    </template>
                  </div>
                  <div class="link-card-text">
                    <font-awesome-icon icon="external-link-alt" class="icon"/>
                    <h4 v-if="movieYokai.title" class="page-title">{{ movieMazica.title }}</h4>
                    <h4 v-else class="page-title" v-html='$t("af_top_49")'></h4>
                    <span class="page-url">{{ movieMazica.url }}</span>
                  </div>
                </div>
              </a>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
        <!-- アニメ -->
        <div class="category-container">
          <h3 class="category-heading">{{ $t("af_top_47") }}</h3>
          <!-- 少年ハリウッド -->
          <div class="case-study-container">
            <p class="resolution-before-after">HD → 4K</p>
            <p class="summary" v-html='$t("af_top_50")'></p>
            <div v-if="parsing" class="loading-indicator-container">
              <v-progress-circular indeterminate color="#FF8538"></v-progress-circular>
            </div>
            <div v-else class="link-card">
              <a :href="animeShohari.url" target="_blank" rel="noopener">
                <div class="link-card-container">
                  <div class="link-card-img">
                    <img v-if="animeShohari.img" :src="animeShohari.img">
                    <template v-else>
                      <img>
                      <font-awesome-icon icon="image" class="icon"/>
                    </template>
                  </div>
                  <div class="link-card-text">
                    <font-awesome-icon icon="external-link-alt" class="icon"/>
                    <h4 v-if="animeShohari.title" class="page-title">{{ animeShohari.title }}</h4>
                    <h4 v-else class="page-title" v-html='$t("af_top_50")'></h4>
                    <span class="page-url">{{ animeShohari.url }}</span>
                  </div>
                </div>
              </a>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
      </div>
    </div>

    <div class="reasons">
      <div class="base-container reasons-container">
        <div class="reason-container">
          <h2 class="reasons-heading">{{ $t("af_top_8") }}</h2>
          <!-- ①  最先端のAI技術を用いて高解像度化 -->
          <v-img class="accuracy_num one" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_num_1.svg"></v-img>
          <h3 class="reasons-subhead">
            <span class="under-dotted">{{ $t("af_top_9") }}</span>
          </h3>
          <p class="accuracy-descriptions">
            <span v-html='$t("af_top_10")'></span>
          </p>
          <h4 class="comparison-heading">{{ $t("af_top_19") }}</h4>
          <div class="comparison-container">
            <div class="comparison-before">
              <div class="before-pic" :style="`background-image:url(${publicImg('3_reasons/several_compare_4a.jpg')})`"></div>
            </div>
            <div class="arrow-container">
              <v-img class="refine-arrow" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/anime_refine_arrow.svg"></v-img>
            </div>
            <div class="comparison-after">
              <div class="after-pic" :style="`background-image:url(${publicImg('3_reasons/several_compare_4b.jpg')})`"></div>
            </div>
          </div>
          <h4 class="comparison-heading">{{ $t("af_top_20") }}</h4>
          <div class="comparison-container">
            <div class="comparison-before">
              <div class="before-pic" :style="`background-image:url(${publicImg('3_reasons/several_compare_2a.jpg')})`"></div>
            </div>
            <div class="arrow-container">
              <v-img class="refine-arrow" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/anime_refine_arrow.svg"></v-img>
            </div>
            <div class="comparison-after">
              <div class="after-pic" :style="`background-image:url(${publicImg('3_reasons/several_compare_2b.jpg')})`"></div>
            </div>
          </div>
          <h4 class="comparison-heading">{{ $t("af_top_21") }}</h4>
          <div class="comparison-container">
            <div class="comparison-before">
              <div class="before-pic" :style="`background-image:url(${publicImg('3_reasons/several_compare_3a_mkp.jpg')})`"></div>
            </div>
            <div class="arrow-container">
              <v-img class="refine-arrow" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/anime_refine_arrow.svg"></v-img>
            </div>
            <div class="comparison-after">
              <div class="after-pic" :style="`background-image:url(${publicImg('3_reasons/several_compare_3b_mkp.jpg')})`"></div>
            </div>
          </div>
        </div>
        <div class="compare-techs">
          <h3 class="reasons-subhead">
            <span>
              {{ $t("af_top_17") }}
            </span>
          </h3>
          <p class="accuracy-descriptions pb-3">
            {{ $t("af_top_18") }}
          </p>
          <div class="three-mkn-container">
            <div class="one-mkn">
              <p class="orange-balloon">
                <span v-html='$t("af_top_11")'></span>
              </p>
              <v-img class="mkn-pics-a" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_1a.jpg" lazy-src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_1a.jpg"></v-img>
              <v-img class="mkn-pics-b" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_1b.jpg" lazy-src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_1b.jpg">
              </v-img>
            </div>
            <div class="one-mkn">
              <p class="orange-balloon">
                <span v-html='$t("af_top_12")'></span>
              </p>
              <v-img class="mkn-pics-a" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_2a.jpg" lazy-src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_2a.jpg"></v-img>
              <v-img class="mkn-pics-b" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_2b.jpg" lazy-src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_2b.jpg"></v-img>
            </div>
            <div class="one-mkn">
              <p class="orange-balloon">
                <span v-html='$t("af_top_13")'></span>
              </p>
              <v-img class="mkn-pics-a" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_3a.jpg" lazy-src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_3a.jpg"></v-img>
              <v-img class="mkn-pics-b last-mkn" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_3b.jpg" lazy-src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_mkn_3b.jpg">
              </v-img>
            </div>
          </div>
          <div class="last-mkn-descriptions-container">
            <p class="last-mkn-descriptions">{{ $t("af_top_14") }}</p>
          </div>
        </div>

        <!-- ②  高解像度化以外の問題にも対応 -->
        <div class="reason-container">
          <v-img class="accuracy_num" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_num_2.svg"></v-img>
          <h3 class="reasons-subhead">
            <span class="under-dotted">{{ $t("af_top_33") }}</span>
          </h3>
          <p class="accuracy-descriptions">{{ $t("af_top_34") }}</p>
          <h4 class="other-probs-heading">{{ $t("af_top_35") }}</h4>
          <p class="other-probs-descriptions">{{ $t("af_top_36") }}</p>
          <v-img class="two-three-pulldown-img" contain :src="publicImg('3_reasons/2_3_pulldown.svg')"></v-img>
          <h4 class="other-probs-heading">{{ $t("af_top_37") }}</h4>
          <p class="other-probs-descriptions">{{ $t("af_top_38") }}</p>
          <v-img class="remove-interlace-img" contain :src="publicImg('3_reasons/remove_interlace.png')"></v-img>
          <h4 class="other-probs-heading">{{ $t("af_top_39") }}</h4>
          <p class="other-probs-descriptions">{{ $t("af_top_40") }}</p>
          <div class="comparison-bokeh-container">
            <div class="comparison-bokeh-before">
              <div class="before-pic" :style="`background-image:url(${publicImg('3_reasons/remove_bokeh_a.jpg')})`"></div>
            </div>
            <div class="arrow-container">
              <v-img class="refine-arrow" :src="publicImg('anime_refine_arrow.svg')"></v-img>
            </div>
            <div class="comparison-bokeh-after">
              <div class="after-pic" :style="`background-image:url(${publicImg('3_reasons/remove_bokeh_b.jpg')})`"></div>
            </div>
          </div>
        </div>

        <!-- ③  多様な色味モードでのアウトプットが可能 -->
        <div class="reason-container">
          <v-img class="accuracy_num" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_num_3.svg"></v-img>
          <h3 class="reasons-subhead">
            <span class="under-dotted" v-html='$t("af_top_41")'></span>
          </h3>
          <p class="accuracy-descriptions">{{ $t("af_top_42") }}</p>
          <v-layout comparison-color row wrap align-center justify-center>
            <v-flex xs12 sm4>
              <v-layout justify-center>
                <v-img class="comparison-color-img" contain :src="publicImg('3_reasons/color_mode_left.jpg')"></v-img>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4>
              <v-layout justify-center>
                <v-img class="comparison-color-img" contain :src="publicImg('3_reasons/color_mode_center.jpg')"></v-img>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4>
              <v-layout justify-center>
                <v-img class="comparison-color-img" contain :src="publicImg('3_reasons/color_mode_right.jpg')"></v-img>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>

        <!-- ④  高スペックマシン不要 -->
        <div class="reason-container">
          <v-img class="accuracy_num" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_num_4.svg"></v-img>
          <h3 class="reasons-subhead">
            <span class="under-dotted">{{ $t("af_top_15") }}</span>
          </h3>
          <p class="accuracy-descriptions">
            {{ $t("af_top_16") }}
          </p>
          <v-img class="gpu-illust" contain :src="publicImg('3_reasons/cloud_gpu_illust.svg')"></v-img>
        </div>
        <!-- ⑤  多様なファイル形式（入力）に対応可能 -->
        <div class="reason-container">
          <v-img class="accuracy_num" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_num_5.svg"></v-img>
          <h3 class="reasons-subhead">
            <span class="under-dotted" v-html='$t("af_top_43")'></span>
          </h3>
          <p class="accuracy-descriptions">{{ $t("af_top_44") }}</p>
        </div>
      </div>
    </div>

    <div class="solutions">
      <div class="base-container">
        <h2 class="solutions-heading">
          <span class="under-yellow-line"><span v-html='$t("af_top_22")'></span></span>
        </h2>
        <div class="panel-container panel-1">
          <v-img class="title-pic display-sp" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_icon_01.svg"></v-img>
          <h3 class="balloon-dotted">
            <span class="balloon-text">{{ $t("af_top_23") }}</span>
            <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_icon_01.svg"></v-img>
          </h3>
          <div class="solutions-panel">
            <p class="panel-description">
              {{ $t("af_top_24") }}
            </p>
              <v-img class="solution-pic" contain :src='$t("af_top_25")' :lazy-src='$t("af_top_25")'></v-img>
            </div>
        </div>
        <div class="panel-container panel-2">
          <v-img class="title-pic display-sp" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_icon_02.svg"></v-img>
          <h3 class="balloon-dotted">
            <span class="balloon-text">{{ $t("af_top_26") }}</span>
            <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_icon_02.svg"></v-img>
          </h3>
          <div class="solutions-panel">
            <p class="panel-description">
              {{ $t("af_top_27") }}
            </p>
            <v-img class="solution-pic" contain :src='$t("af_top_28")' :lazy-src='$t("af_top_28")'></v-img>
          </div>
        </div>
      </div>
    </div>
    <div class="milky-panic">
      <div class="base-container">
        <h2 class="mkp-heading" v-html='$t("af_top_29")'>
        </h2>
        <p class="mkp-paragraph" v-html='$t("af_top_30")'></p>
        <p class="mkp-paragraph" v-html='$t("af_top_31")'></p>
        <p class="mkp-paragraph" v-html='$t("af_top_32")'></p>
      </div>
    </div>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeqL6b20Zka_nA8ujh1CfNZF0z42Sqvi2cp0BV5jZ8YdBGLZA/viewform?embedded=true" id="g_form" width="100%" frameborder="0" marginheight="0" marginwidth="0" class="google-form pt-12">now loading ...</iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      showBtn: false,
      imgRoot: 'https://storage.googleapis.com/anime_refiner_public_files/01_top/',
      comparison_pics: [
        [
          "https://storage.googleapis.com/anime_refiner_public_files/01_top/2_what-is-ar/compare_pic_1a_mkp.jpg",
          "https://storage.googleapis.com/anime_refiner_public_files/01_top/2_what-is-ar/compare_pic_1b_mkp.jpg"
        ],
        [
          "https://storage.googleapis.com/anime_refiner_public_files/01_top/2_what-is-ar/compare_pic_2a_mkp.jpg",
          "https://storage.googleapis.com/anime_refiner_public_files/01_top/2_what-is-ar/compare_pic_2b_mkp.jpg"
        ]
      ]
    }
  },
  computed: {
    ...mapGetters('preview', [
      'parsing',
      'movieYokai',
      'movieMazica',
      'animeShohari',
    ]),
  },
  methods: {
    getOffset(targetID) {
      let target = document.getElementById(targetID)
      let targetRect = target.getBoundingClientRect()
      return window.pageYOffset + targetRect.top
    },
    // show_btn
    showFixedButton() {
      if ((window.scrollY > this.getOffset('show_btn')) || window.parent.screen.width < 768) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
      if (window.scrollY > (this.getOffset('g_form')-680)) {
        this.showBtn = false
      }
    },
    publicImg(path) {
      return this.imgRoot + path;
    }
  },
  created () {
    this.$store.dispatch('preview/getPreviewData')
  },
  mounted () {
    window.addEventListener('scroll', this.showFixedButton)
  }
}
</script>

<style lang="scss" scoped>
@import url(https://use.typekit.net/ddm1tzj.css);
@import "../assets/scss/_variables.scss";

@mixin franklin_font {
  font-family: 'franklin-gothic-urw', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

* {
  font-family: 'noto-sans-cjk-jp', 'Noto Sans CJK JP', 'Source Han Sans JP', '\30D2\30E9\30AE\30CE\89D2\30B4   ProN W3', 'Hiragino Kaku Gothic ProN', 'HiraginoSans-W3', 'Avenir', Helvetica, Arial, sans-serif;
}

@mixin container_base($width: 1040px) {
  padding: 0 56px;
  overflow: hidden;

  @include mq-down(md) {
    padding: 0 16px;
  }

  .base-container {
    position: relative;
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
  }
}

.mainvisual {
  @include container_base(1440px);

  .base-container {
    width: 100%;
  }

  padding: 0 40px;
  background-color: gray;
  max-height: 720px;
  height: 50vw;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:after {
    content: '';
    box-shadow: 0 40px 200px #000000;
    position: absolute;
    height: 20%;
    width: 200%;
    top: -20%;
    left: -50%;
  }

  @include mq-down {
    padding: 0 10px;
    height: 137.3vw;
    max-height: 820px;
    min-height: 425px;
    background-image: url('https://storage.googleapis.com/anime_refiner_public_files/01_top/1_main-visual/mv_image_sp.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.mv-title-box {
  background-color: #EBFF52;
  width: 250px;
  padding: 16px;
  position: relative;
  text-align: left;
  top: 40px;
  left: 15px;
  z-index: 2;

  .mv-h1 {
    font-size: 32px;
    font-weight: 200;
    letter-spacing: 1.28px;
    line-height: 1.4;
  }

  .mv-h2 {
    @include franklin_font;
    font-size: 18px;
    font-weight: 500;
    margin-top: 12px;
    line-height: 1.4;
  }

  @include mq-down(xxl) {
    width: 16vw;
    max-width: 250px;
    top: 20px;
    left: -20px;
    padding: calc(1.64vw + -7.6px);
    .mv-h1 {
      font-size: calc(2.24vw + -0.2px);
      font-weight: 200;
      letter-spacing: 1.28px;
      line-height: 1.3;
    }
    .mv-h2 {
      font-size: calc(0.45vw + 9.6px);
      letter-spacing: 0.56px;
      line-height: 1.3;
      margin-top: 4px;
    }
  }

  @include mq-down(md) {
    width: 250px;
    height: 250px;
    max-width: initial;
    max-height: initial;
    left: 10px;
    padding: 10px;

    .mv-h1 {
      font-size: 35px;
      letter-spacing: 1.04px;
      line-height: 1.3;
    }
    .mv-h2 {
      font-size: 19px;
      letter-spacing: 0.56px;
    }
  }

  @include mq-down(sm) {
    margin-top: 2px;
    padding: 10px;
    width: 48vw;
    height: 48vw;
    max-width: 250px;
    max-height: 250px;
    top: 12px;
    left: 2px;
    .mv-h1 {
      font-size: calc(8vw + -4.6px);
    }
    .mv-h2 {
      font-size: calc(3.85vw + -0.3px);
      margin-top: 5px;
    }
  }
}

.mv-cv-btn {
  color: white;
  width: 512px !important;
  max-width: 88vw !important;
  white-space: normal;
  box-sizing: content-box;
  min-height: 96px;
  font-size: 28px !important;
  font-weight: 400;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 64px;
  margin-top: auto;
  padding: 0 30px !important;
  border-radius: 99px;
  background: linear-gradient(120deg, #FCB94D, #C1230F);
  box-shadow: 0 2px 10px rgba(0,0,0,0.4) !important;
  position: relative;
  z-index: 5;
  &::before {
    content: '';
    background: linear-gradient(120deg, rgb(255, 202, 117), rgb(241, 58, 34));
    // background-color: #EBFF52;
    opacity: 0;
    transition: .2s;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &:hover {
    box-shadow: 1px 6px 15px rgba(0,0,0,0.4) !important;
    &::before {
      opacity: .5 !important;
    }
  }

  @include mq-down(lg) {
    margin-bottom: 32px;
  }

  @include mq-down(md) {
    min-height: initial;
    padding: 20px 20px !important;
    font-size: calc(2.24vw + 10.8px) !important;
    height: initial !important;
    min-height: initial !important;
    position: fixed;
    box-sizing: border-box;
    max-width: 92vw !important;
    transform: translateX(-50%);
    left: 50%;
    bottom: 40px;
    margin-bottom: 16px;

    ::v-deep .v-btn__content {
      line-height: 1.3;
    }
  }

  &.fixed-btn {
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: .4s;
    visibility: hidden;
  }
  &.show-btn {
    visibility: visible;
    opacity: 1;
  }
}

.mv-video {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.what-is-ar {
  padding: 0 40px;
  @include container_base;

  .icon-container {
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    width: 110px;
    height: 110px;
    border-radius: 999px;
    margin: 30px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq-down {
      margin-top: 64px;
      width: 96px;
      height: 96px;
    }
  }

  .ar-logo-img {
    width: 55%;
    margin-left: 10px;
  }

  .whatis-heading {
    text-align: center;
    margin-top: 16px;
    font-size: 40px;
    font-weight: 500;

    @include mq-down(md) {
      font-size: calc(2.24vw + 22.8px);
      margin-bottom: 24px;
      padding: 0;
      font-weight: 500;
    }
  }

  .whatis-paragraph {
    margin-top: 28px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 1.8;
    margin-bottom: 128px;

    @include mq-down(lg) {
      font-size: calc(1.96vw + 3.9px);
    }

    @include mq-down {
      font-size: 16px;
    }
  }

  .whatis-subheading {
    text-align: center;
    font-size: 30px;
    font-weight: 500;

    @include mq-down(md) {
      font-size: calc(7vw - 3px);
      margin-bottom: 24px;
      padding: 0 24px;
      font-weight: 500;
    }
  }

  .appeal-movie-container {
    max-width: 1040px;
    margin: 50px auto 80px;
    position: relative;
    width: 100%;
    &:before {
      content:"";
      display: block;
      padding-top: 56.25%; /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
    }
  }

  .appeal-movie {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // 左右比較パネル
  .comparisons {
    margin: 128px 0 160px;

    @include mq-down(sm) {
      margin-top: 0;
      margin-bottom: 64px;
    }

    .comparison-container {
      margin-top: 24px;
      position: relative;

      @include mq-down(md) {
        margin: 68px 0 0;
        padding: 0 24px;
      }

      @include mq-down(sm) {
        margin-top: 24px;
        padding: 0;
      }

      @mixin comparison_pic {
        background-size: cover;
        background-repeat: no-repeat;
        max-height: 336px;
        max-width: 560px;
        height: 26vw;
        width: calc(53.8vw - 60px);

        @include mq-down(md) {
          height: 40vw;
          max-width: initial;
          width: initial;
        }
      }
      .comparison-before {
        position: relative;
        top: 0;
        left: 0;

        .before-pic {
          @include comparison_pic;
          background-position-x: right;

          clip-path: polygon(0% 0%, 99% 0%, 85.5% 100%, 0% 100%);
          @include mq-down(md) {
            clip-path: none;
          }
        }
      }

      .comparison-after {
        position: absolute;
        top: 0;
        right: 0;

        @include mq-down(md) {
          position: relative;
          margin-top: 10px;
        }

        .after-pic {
          @include comparison_pic;
          background-position-x: left;

          clip-path: polygon(14.5% 0%, 100% 0%, 100% 100%, 1% 100%);
          @include mq-down(md) {
            clip-path: none;
          }
        }
      }

      .arrow-container {
        background-color: white;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        position: absolute;
        bottom: 118px;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
        overflow: hidden;
        box-shadow: 0px 3px 6px rgba(black, .16);

        @include mq-down(md) {
          top: initial;
          transform: rotate(90deg) translateX(50%);
          height: 18vw;
          width: 18vw;
          bottom: 50%;
        }

        .refine-arrow {
          margin: 24% 16% 0 20%;
        }
      }
    }
  }
}

.case-study {
  @include container_base;
  margin-bottom: 200px;
  .case-study-heading {
    color: #FF8538;
    font-size: 60px;
    font-weight: 500;
    margin-bottom: 80px;
    text-align: center;
    @include mq-down(xxl) {
      font-size: 56px;
    }
    @include mq-down(lg) {
      font-size: 5vw;
    }
    @include mq-down {
      font-size: calc(2.24vw + 22.8px);
      margin-top: 60px;
      margin-bottom: 40px;
      padding: 0;
    }
  }
  .category-container {
    margin-top: 60px;
    .category-heading {
      background-color: #EBFF52;
      font-size: 30px;
      font-weight: 500;
      padding: 0 20px;
      text-align: left;
      @include mq-down(lg) {
        font-size: 27px;
      }
      @include mq-down {
        font-size: 22px;
        padding: 0 8px;
      }
    }
    .case-study-container {
      .resolution-before-after {
        background-color: #FF8538;
        border-radius: 20px;
        color: white;
        display: inline-block;
        font-size: 20px;
        margin: 30px 0 10px;
        padding: 5px 18px;
        text-align: left;
        @include mq-down {
          font-size: 18px;
          padding: 3px 12px;
        }
      }
      .loading-indicator-container {
        margin: 20px auto;
        text-align: center;
      }
      .link-card {
        background-color: #EFEFEF;
        margin-bottom: 20px;
        width: 100%;
        a {
          color: #707070;
          text-decoration: none;
          .link-card-container {
            display: flex;
            flex-flow: row;
            @include mq-down {
              flex-flow: column;
            }
            .link-card-img {
              position: relative;
              width: 32%;
              min-height: 120px;
              @include mq-down {
                width: 100%;
              }
              .icon {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                font-size: 1.5em;
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit:cover;
              }
            }
            .link-card-img:before {
              content: "";
              display: block;
              padding-top: 56.25%;
            }
            .link-card-text {
              position: relative;
              width: 68%;
              @include mq-down {
                width: 100%;
              }
              .icon {
                position: absolute;
                top: 2px;
                right: 2px;
              }
              display: flex;
              align-items: center;
              .page-title {
                font-size: 20px;
                padding: 0 22px;
                @include mq-down(lg) {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                @include mq-down {
                  font-size: 16px;
                  padding: 23px 10px;
                  white-space: initial;
                }
              }
              .page-url {
                position: absolute;
                bottom: 8px;
                color: #424242;
                display: inline-block;
                font-size: 14px;
                padding-left: 22px;
                padding-right: 22px;
                @include mq-down(lg) {
                  font-size: 12px;
                }
                @include mq-down {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.reasons {
  background-color: #EBFF52;
  overflow: hidden;
  @include container_base(1200px);
  padding: 0;

  .reasons-container {
    padding-top: 0px;
    padding-bottom: 138px;
    margin-top: 0;

    @include mq-down {
      padding-top: 64px;
      padding-bottom: 50px;
    }
    @include mq-down(sm) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .reason-container {
      max-width:  1040px;
      margin: 100px auto 0 auto;
      @include mq-down(lg) {
        margin: 50px auto 0 auto;
      }
    }
    .reasons-heading {
      font-size: 60px;
      font-weight: 500;
      color: #FF8538;
      text-align: center;
      @include mq-down(xxl) {
        font-size: 56px;
      }
      @include mq-down(lg) {
        font-size: 5vw;
      }
      @include mq-down {
        font-size: calc(2.24vw + 22.8px);
        padding: 0;
      }
    }
    .accuracy-descriptions {
      text-align: center;
      font-size: 20px;
      line-height: 40px;
      padding: 25px 40px 0;

      @include mq-down(xl) {
        font-size: 18px;
        line-height: 36px;
      }
      @include mq-down(lg) {
        font-size: calc(0.86vw + 7.4px);
        line-height: calc(2.59vw + 6.1px);
      }
      @include mq-down {
        font-size: 14px;
        line-height: 1.6;
        text-align: left;
        margin-bottom: 18px;
        padding: 25px 0 0;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .accuracy_num {
      width: 72px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
      background-color: white;
      border-radius: 50%;
      @include mq-down {
        margin-bottom: 16px;
        width: 65px;
      }
      &.one {
        margin-top: 100px;
        @include mq-down(lg) {
          margin-top: 50px;
        }
      }
    }
    .reasons-subhead {
      text-align: center;
      position: relative;
      padding: 0 40px;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: -4px;
      @include mq-down(lg) {
        font-size: 27px;
      }
      @include mq-down {
        display: block;
        font-size: 22px;
        margin-bottom: -4px;
        padding: 0 24px;
      }
      @include mq-down {
        padding: 0;
      }
      .under-dotted {
        display: inline-block;
        background-image: url(https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/accuracy_h3_dotted.svg);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 24px;
        padding-bottom: 32px;
        @include mq-down {
          padding-bottom: 36px;
        }
      }
    }
    .three-mkn-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include mq-down(xl) {
        padding: 0 40px;
      }

      @include mq-down(md) {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: 0;
        margin: 0 24px;
      }
      @include mq-down(sm) {
        margin: 0;
      }
    }
    .one-mkn {
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: stretch;
      width: 320px;
      position: relative;

      @include mq-down(xl) {
        width: 30%;
      }

      @include mq-down(md) {
        margin-top: 64px;
        padding: 0;
        width: initial;
      }

      @include mq-down(sm) {
        margin: 0;
        padding: 0;
        &:not(:last-child) {
          margin: 0 0 52px;
        }
      }

      .orange-balloon {
        text-align: center;
        background-color: #FF8538;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: white;
        font-size: calc(1.57vw + 4px);
        font-weight: 500;
        margin-bottom: 38px;
        position: relative;
        padding: 11px 14px;
        line-height: 1.3;
        min-height: 76px;

        @include mq-up(xl) {
          font-size: 20px;
        }

        @include mq-down(xl) {
          min-height: 75px;
        }

        @include mq-down {
          font-size: 20px;
        }

        &::after {
          content: '';
          background-image: url(https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/orange_balloon_tail.svg);
          background-position: center;
          background-repeat: no-repeat;
          height: 30px;
          width: 38.84px;
          position: absolute;
          bottom: -26.5px;
          transform: translateX(-50%);
          left: 50%;
        }
      }
      .mkn-pics-a {
        margin-bottom: 56px;
      }
      .mkn-pics-b {
        position: relative;
        overflow: visible;
        &:before {
          content: '';
          background-image: url(https://storage.googleapis.com/anime_refiner_public_files/01_top/3_reasons/expansion_dotted_line_mkp.svg);
          background-position: bottom center;
          background-repeat: no-repeat;
          height: 100%;
          width: 103%;
          left: -1%;
          margin: auto;
          position: absolute;
          bottom: 99%;
        }

        &.last-mkn {
          @include mq-up(md) {
            &:after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              border: solid 6px #C1230F;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .last-mkn-descriptions-container {
      display: flex;
      justify-content: flex-end;
    }
    .last-mkn-descriptions {
      margin-top: 8px;
      width: 320px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      @include mq-down(cn) {
        width: 27%;
        margin-right: 24px;
        font-size: 18px;
        line-height: 26px;
      }
      @include mq-down(md) {
        margin-right: 0;
        padding: 0 80px;
        width: initial;
        float: initial;
        font-size: 16px;
        line-height: 24px;
      }
      @include mq-down(sm) {
        padding: 0 24px;
      }
    }
    .gpu-illust {
      max-width: 920px;
      padding: 0 32px;
      margin: 48px auto 0;
      @include mq-down {
        margin-top: 16px;
      }

      @include mq-down(sm) {
        padding: 0;
      }
    }
    .comparison-heading {
      text-align: center;
      margin-top: 64px;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 12px;

      @include mq-down {
        margin-top: 40px;
        font-size: 20px;
      }
    }
    .comparison-container {
      position: relative;
      background-color: white;
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @include mq-up(md) {
        margin-right: 32px;
        margin-left: 32px;
      }

      @include mq-down(md) {
        background-color: initial;
      }
    }

    @mixin comparison_pic {
      background-size: cover;
      background-repeat: no-repeat;
      max-height: 280px;
      height: 27vw;
      max-width: 560px;
      width: 560px;
      width: calc(57vw - 33px);
      @include mq-down(md) {
        max-height: initial;
        max-width: initial;
        height: 32vw;
        width: initial;
      }
    }

    .comparison-before {
      position: relative;
      top: 0;
      left: 0;

      .before-pic {
        @include comparison_pic;
        background-position-x: right;
        clip-path: polygon(0% 0%, 90.5% 0%, 82.5% 100%, 0% 100%);

        @include mq-down(md) {
          clip-path: none;
        }
      }
    }
    .comparison-after {
      position: absolute;
      top: 0;
      right: 0;

      @include mq-down(md) {
        position: relative;
        margin-top: 5px;
      }

      .after-pic {
        @include comparison_pic;
        background-position-x: left;
        clip-path: polygon(17.5% 0%, 100% 0%, 100% 100%, 9.5% 100%);

        @include mq-down(md) {
          clip-path: none;
        }
      }
    }
    .arrow-container {
      background-color: white;
      height: 90px;
      width: 90px;
      border-radius: 50%;
      position: absolute;
      bottom: 50%;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
      overflow: hidden;
      box-shadow: 0px 3px 6px rgba(black, .16);
      transform: translateY(50%);

      @include mq-down(md) {
        transform: rotate(90deg) translateX(50%);
        height: 13vw;
        width: 13vw;
        bottom: 50%;
      }

      .refine-arrow {
        margin: 24% 16% 0 20%;
      }
    }
    .compare-techs {
      background-color: #F9FCE7;
      padding: 74px 108px 12px 108px;
      margin: 68px auto 0px auto;
      @include mq-down(xl) {
        padding: 37px 24px 12px 24px;
        margin: 20px 20px 0px 20px;
      }
      @include mq-down(sm) {
        margin: 40px 0px 0px 0px;
      }
    }
    .other-probs-heading {
      text-align: center;
      margin-top: 100px;
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 12px;

      @include mq-down(lg) {
        margin-top: 50px;
        margin-bottom: 0px;
      }
      @include mq-down {
        font-size: 20px;
      }
    }
    .other-probs-descriptions  {
      text-align: left;
      padding: 20px 220px 0;
      font-size: 20px;
      line-height: 40px;

      @include mq-down(xl) {
        font-size: 18px;
        line-height: 36px;
      }
      @include mq-down(lg) {
        font-size: calc(0.86vw + 7.4px);
        line-height: calc(2.59vw + 6.1px);
      }
      @include mq-down {
        padding: 25px 40px 0;
      }
      @include mq-down {
        font-size: 14px;
        line-height: 1.6;
        margin-bottom: 18px;
        padding: 25px 0 0;
      }
    }
    .two-three-pulldown-img {
      max-width: 560px;
      margin: 0 auto;
    }
    .remove-interlace-img {
      max-width: 580px;
      margin: 0 auto;
    }
    .comparison-bokeh-container  {
      margin-top: 38px;
      position: relative;
      margin-right: 58px;
      margin-left: 58px;
      @include mq-down(lg) {
        margin-right: 32px;
        margin-left: 32px;
      }
      @include mq-down(sm) {
        margin-right: 0px;
        margin-left: 0px;
      }
    }
    @mixin comparison_bokeh-pic {
      background-size: contain;
      background-repeat: no-repeat;
      max-height: 257px;
      height: 27vw;
      max-width: 457px;
      width: calc(50vw - 33px);
      @include mq-down(sm) {
        max-height: initial;
        max-width: initial;
        height: 54vw;
        width: initial;
      }
    }
    .comparison-bokeh-before {
      position: relative;
      top: 0;
      left: 0;
      .before-pic {
        @include comparison_bokeh-pic;
        background-position-x: right;
      }
    }
    .comparison-bokeh-after {
      position: absolute;
      top: 0;
      right: 0;
      @include mq-down(md) {
        position: relative;
        margin-top: 5px;
      }
      .after-pic {
        @include comparison_bokeh-pic;
        background-position-x: left;
      }
    }
    .comparison-color {
      @include mq-down(lg) {
        padding-right: 32px;
        padding-left: 32px;
      }
    }
    .comparison-color-img {
      max-width: 310px;
      @include mq-down(md) {
        margin-top: 10px;
      }
    }
  }
}

.solutions {
  @include container_base(1028px);
  padding: 0 40px 20px;

  .solutions-heading {
    margin-top: 128px;
    text-align: center;
    font-size: 48px;

    @include mq-down(xxl) {
      font-size: calc(3.33vw + 0px);
      margin-bottom: calc(1.67vw + 12px);
    }

    @include mq-down(xl) {
      font-size: 40px;
      padding: 0 24px;
      margin-bottom: 32px;
    }

    @include mq-down(lg) {
      font-size: 32px;
      padding: 0 24px;
      margin-bottom: 32px;
      font-weight: 500;
    }

    @include mq-down(md) {
      font-size: calc(7vw - 3px);
      margin-bottom: 24px;
      padding: 0;
      font-weight: 500;
    }
  }
  .under-yellow-line {
    font-weight: 600;
    background: linear-gradient(transparent 75%, #FFFF12 25%);
  }

  .panel-container {

    .solutions-panel {
      position: relative;
      display: flex;
      box-shadow: 0px 3px 10px rgba(black, .25);
      background-color: #FFF5E5;

      @include mq-down {
        flex-direction: column;
        margin: 0 -24px;
      }

      .panel-description {
        width: 438px;
        background-color: white;
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        line-height: 40px;
        padding: 32px 30px 32px 48px;
        margin: 0;
        @include mq-down(xxl) {
          font-size: 18px;
          line-height: 36px;
        }
        @include mq-down(lg) {
          font-size: calc(0.86vw + 7.4px);
          line-height: calc(1.72vw + 14.8px);
        }
        @include mq-down(md) {
          font-size: 14px;
          line-height: 1.7;
          padding: 13px 24px 24px;
          width: initial;
        }
      }
    }
  }

  .balloon-dotted {
    margin: 120px 0 55px;
    padding: 32px 32px;
    position: relative;
    display: block;
    text-align: center;
    min-width: 120px;
    max-width: 100%;
    color: #424242;
    border: dashed 3px #424242;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #EBFF52;
    z-index: 3;
    @include mq-down(md) {
      padding: 8px 16px;
      margin-top: 15px;
    }
    &:before {
      content: "";
      height: 50px;
      width: 60px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      background-color: #EBFF52;
      transform: translateX(-50%);
      z-index: 1;
      @include mq-down(md) {
        z-index: -1;
        height: 30px;
      }
    }
    &:after {
      content: '';
      background-image: url(https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/balloondotted_tail.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 51.01px;
      height: 69.8px;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
      bottom: -47.5px;
      z-index: 0;
      @include mq-down(md) {
        background-image: url(https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/balloondotted_tail_sp.svg);
        bottom: -20px;
        width: 25px;
        height: 32px;
        z-index: -2;
      }
    }

    .balloon-text {
      font-size: 32px;
      line-height: 48px;
      font-weight: 600;
      position: relative;
      z-index: 3;
      @include mq-down(lg) {
        font-size: 26px;
        line-height: 38px;
      }
      @include mq-down(sm) {
        font-size: 17px;
        line-height: 13px;
      }
    }
  }

  .solution-pic {
    width: 590px;
    background-color: #FFF5E5;

    @include mq-down(xl) {
      width: 50%;
    }

    @include mq-down(md) {
      margin: 20px auto 0;
      width: initial;
      max-width: 368px;
    }

    @include mq-down(sm) {
      margin: 0 10px;
    }
  }
  @mixin title_pic {
    position: absolute;

    @include mq-down(md) {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 32px auto 0;
    }
  }
  @mixin yellow_balloon {
    position: absolute;
    background: #FFFF00;
    border-radius: 4px;
    padding: 16px 20px;
    margin: 0;
    min-height: 80px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    max-width: 400px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    z-index: 5;
    &::before {
      content: '';
      background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/yellow-balloon-tail.svg);
      background-size: cover;
      height: 35px;
      width: 28.86px;
      position: absolute;
      z-index: -1;
    }
    @include mq-down(md) {
      padding: 8px 32px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      position: relative;
      top: initial;
      left: initial;
      bottom: initial;
      right: initial;
      margin: 16px auto 0;
      justify-content: center;
      min-height: 50px;

      &::before {
        transform: translateX(-50%) rotate(180deg);
        top: -30px;
        left: 50%;
      }
    }
    @include mq-down(sm) {
      margin: 16px 24px 0;
    }
  }
  .panel-1 {
    .balloon-dotted {
      @include mq-up(md) {
        padding-right: 156px;
        padding-left: 156px;
      }
      @include mq-up(lg) {
        padding-right: 200px;
        padding-left: 200px;
      }
    }
    .title-pic {
      @include title_pic;
      width: 18%;
      left: 2%;
      bottom: 70%;
      transform: translateY(50%);
      @include mq-down(md) {
        width: 120px;
        transform: initial;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        bottom: -14px;
        right: 44%;
        &::before {
          transform: translateY(-50%) rotate(-90deg);
          right: -27px;
          top: 50%;
        }
      }
    }
    .panel-description {
      @include mq-up(md) {
        padding-bottom: 70px;
      }
    }
  }
  .panel-2 {
    .balloon-dotted {
      @include mq-up(md) {
        padding-right: 128px;
        padding-left: 128px;
      }
      @include mq-up(lg) {
        padding-right: 156px;
        padding-left: 156px;
      }
    }
    .title-pic {
      @include title_pic;
      width: 14%;
      right: -27px;
      bottom: 45px;
      @include mq-down(md) {
        width: 120px;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        top: 32px;
        left: -30px;
        &::before {
          transform: translateX(-50%);
          bottom: -30px;
          left: 50%;
        }
      }
    }
  }
}

.milky-panic {
  @include container_base;

  padding-top: 96px;
  padding-bottom: 96px;
  margin-top: 128px;
  background-color: #F5F5F5;

  @include mq-down {
    padding-top: 56px;
    padding-bottom: 48px;
  }

  .mkp-heading {
    margin-bottom: 32px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;

    span {
      white-space: nowrap;
    }

    @include mq-down(lg) {
      font-size: 30px;
    }

    @include mq-down(md) {
      font-size: calc(3.13vw + 6px);
      margin-bottom: 24px;
      padding: 0;
      font-weight: 600;
      text-align: left;
    }
  }

  .mkp-paragraph {
    margin-top: 8px;
    // text-align: center;
    font-size: 20px;
    line-height: 40px;

    @include mq-down(xl) {
      font-size: 18px;
      line-height: 36px;
    }
    @include mq-down(lg) {
      font-size: calc(0.86vw + 7.4px);
      line-height: calc(2.59vw + 6.1px);
    }
    @include mq-down {
      font-size: 14px;
      line-height: 1.6;
      text-align: left;
    }

    a {
      color: #3949AB;
    }
  }
}

.google-form {
  margin-top: 128px;
  height: 1400px;

  @include mq-down {
    margin-top: 64px;
    height: 1600px;
  }
}

</style>
<i18n>
{
  "ja": {
    "af_top_1": "アニメをAIで<br>高解像度化<br>しよう。",
    "af_top_2": "Let’s make <span class='nowrp'>high-resolution</span> Anime easily",
    "af_top_3": "アニメの高解像度化<br class='hide-md-up'>について相談する",
    "af_top_4": "AnimeRefinerとは",
    "af_top_5": "AnimeRefinerはアニメを綺麗に高解像度化することができるAIです。<br class='hide-md-down'>どのようなアニメでも縦4倍、横4倍に高解像度化することができ、<br class='hide-md-down'>まるでそのサイズで制作されたかのような美しい映像に変換することが可能です。<br class='hide-md-down'>SDサイズ（640 × 480px）のアニメならFHDサイズ以上、<br class='hide-md-down'>HDサイズ（1280 × 720px）のアニメなら4Kサイズ以上、<br class='hide-md-down'>フルHDサイズ（1920 × 1080px）のアニメなら8Kサイズに変換できます。<br class='hide-md-down'>Deep Learningを用いた独自の技術で開発を行っており、これまでの技術とは比較にならないほど美しく、アニメを高解像度化できます。",
    "af_top_6": "AnimeRefiner・変換サンプル動画",
    "af_top_7": "高解像度化例",
    "af_top_8": "AnimeRefinerの特徴",
    "af_top_9": "最先端のAI技術（Deep Learning）を用いて高解像度化",
    "af_top_10": "AnimeRefinerはDeep Learning を活用することで、従来の画像を引き伸ばして中間を補間する変換（アップコンバート）技術では不可能だった、高品質な状態での高解像度化が可能です。<br>高解像度化AI「<a href='https://cre8tiveai.com/pr'>Photo Refiner</a>」で培った独自のAIモデルにより、アニメを縦横4倍のサイズに高解像度化できます。低解像度な動画と高解像度な動画の特徴を大量に学習したAIは、これまでの技術とは比較にならないほど美しくアニメを高解像度化することができます。",
    "af_top_11": "変換前のアニメ",
    "af_top_12": "<span class='text-no-wrap'>既存技術での</span><span class='text-no-wrap'>アップコンバート例</span>",
    "af_top_13": "<span class='text-no-wrap'>AnimeRefinerで</span><span class='text-no-wrap'>高解像度化</span>",
    "af_top_14": "既存技術で高解像度化するよりも、エッジがシャープになっており、細い輪郭線がなめらかに表現されます。",
    "af_top_15": "高スペックマシン不要",
    "af_top_16": "ハードウェア面では、高性能なGPUクラスタを採用し、ソフトウェア面でも特許出願中の高速推論技術により、高速な推論を実現しました。 例えば、CG分野では高品質な画像を得るためには、従来では時間を掛けて自社のマシンでレンダリングを行う必要がありました。AnimeRefinerを使えば、ご自身のハードウェアを動かすことなく、キレイなアニメ映像に変換することができます。",
    "af_top_17": "既存技術との比較",
    "af_top_18": "多くの変換（アップコンバート）技術は、動画を引き伸ばして中間を補間しています。そのためエッジが曖昧だったり、ノイズがそのまま引き伸ばされたりします。AnimeRefinerでは、高画質な映像にあるべき状態を学習しているので、低解像度の映像から高解像度の映像を創り出すことができます。",
    "af_top_19": "1.様々な種類のノイズの除去",
    "af_top_20": "2.AIが推定して生成することでエッジがシャープに",
    "af_top_21": "3.陰影も綺麗に",
    "af_top_22": "リマスターおよび4K・8Kアニメ制作の<br class='hide-md-down'>時間とコストを削減",
    "af_top_23": "過去のアニメを低コスト・スピーディーに4Kリマスター化",
    "af_top_24": "アニメのリマスターの制作には、とても膨大な時間とコストがかかります。高解像度化したいサイズまで引き伸ばした後、ぼやけてしまった箇所を手で描きなおして修復。映像のノイズなどを除去しながら、1フレームずつキレイに修復する必要があります。 AnimeRefinerなら、AIが高解像度なアニメを推測してリマスタリングします。映像の中にあるノイズやゴミなどを取り除いて、美しいリマスター版を作ることが可能です。90%以上のリマスター作業をAIが代替してくれます。",
    "af_top_pic1_a": "過去のアニメーション",
    "af_top_pic1_b": "リマスター版が完成",
    "af_top_pic1_c": "高解像度化",
    "af_top_pic1_d": "ノイズ除去例",
    "af_top_25": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_1_ja.jpg",
    "af_top_26": "4Kアニメーション制作の負担軽減",
    "af_top_27": "4Kテレビの普及に伴い、4Kコンテンツのニーズも高まっています。しかしながら、フルHDサイズで原画を描くのと比べ、4Kサイズで原画を描くのでは2倍以上の作業時間が必要です。AnimeRefinerを使えばHDサイズで描いた原画を4Kにアップコンバートするという新しい制作手法が実現できます。これによりより低コスト・スピーディーに4K・8Kといった高画質なアニメを制作することが可能です。4K・8Kアニメーション制作の予算削減効果が期待できます。",
    "af_top_pic2_a": "HDサイズ で制作",
    "af_top_pic2_b": "AIによって4Kに",
    "af_top_28": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_2_ja.jpg",
    "af_top_29": "<span class='nowrp'>アニメ</span>\n<span class='nowrp'>「ミルキーパニック -twelve-」</span>\n<span class='nowrp'>について</span>",
    "af_top_30": "ミルキーパニックは、十二支の動物たちと猫族との戦いを、<br class='hide-md-down'>西部劇風のタッチとユニークなキャラクターで描いた<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'>TOMASON</a>のオリジナルアニメです。",
    "af_top_31": "<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'>TOMASON</a>は、『ふるさと再生 日本の昔ばなし』シリーズをはじめ、VPやCM、プラネタリウム番組、３DCGモデリング、さらには映像コンテンツのシナリオから音楽、音響制作、編集まで、多岐にわたる活動を展開し続けるアニメーション＆CGスタジオです。ミルキーパニックに関するお問い合わせは<a href='https://www.tomason.co.jp/contact' target='_blank'>コチラ</a>にお願いします。",
    "af_top_32": "なお、サイトおよびYoutubeにアップロードしている動画は、AnimeRefinerの高解像度化の効果を分かりやすくするために、FHDの映像をダウンコンバートした後にAnimeRefinerの高解像度化の処理を施しております。",
    "af_top_33": "高解像度化以外の問題にも対応",
    "af_top_34": "アニメや実写映像で問題となる、2-3プルダウンへの対応やインターレースへの対応も行うことができます。AnimeRefiner では、Deep Learning（ディープラーニング） やComputer Vision（コンピュータービジョン）等の知見を活かしてこれらの問題に対応することができます。",
    "af_top_35": "2-3プルダウン",
    "af_top_36": "毎秒24コマで記録されたアニメを、テレビ放送やVOD（ビデオオンデマンド）などで用いられる毎秒30フレームの映像信号に変換する手法。",
    "af_top_37": "インターレース解除",
    "af_top_38": "一般的なアナログテレビ信号または1080iフォーマットのHDTV信号などのインターレース映像を非インターレース形式に変換するプロセス。",
    "af_top_39": "字幕と映像を分離した高解像度化処理",
    "af_top_40": "字幕はアニメを制作後に合成されているため、字幕と映像で異なる処理が行われているケースがあります。そのため、一元的に処理を行うと、アニメを綺麗にすることができても、字幕が綺麗にならないことがあります。そこで、AnimeRefiner では、字幕は字幕だけで処理、アニメはアニメだけで処理を行い、後で合成することで字幕と映像が混在する場面においても綺麗な高解像度化を実現できるようになりました。",
    "af_top_41": "多様な色味モードでの<br class='hide-md-up'>アウトプットが可能",
    "af_top_42": "元々のアニメの色味を残したまま高解像度化するモードから、AIを用いて見栄えがする色味まで複数パターンでアウトプットすることができるモードを追加いたしました。",
    "af_top_43": "多様なファイル形式（入力）に<br class='hide-md-up'>対応可能",
    "af_top_44": "Apple ProRes 422 やH264、MPEG-4 をはじめ多様なエンコードに対応しているので、.movや.mp4、.mpeg 等の様々なファイル形式を高画質化することが可能です。なお、出力は.movを基本としつつ、要望に応じて対応することができます。詳細については、お問い合わせください。",
    "af_top_45": "AnimeRefinerの導入実績",
    "af_top_46": "映画",
    "af_top_47": "アニメ",
    "af_top_48": "『映画妖怪ウォッチ♪ ケータとオレっちの出会い編だニャン♪ワ、ワタクシも～♪♪』<br>©LEVEL-5/妖怪ウォッチ♪プロジェクト・テレビ東京",
    "af_top_49": "『劇場版 総集編 マジカパーティ』<br>©MAZICA PARTY PROJECT・TVO",
    "af_top_50": "『少年ハリウッド』<br>©NJA"
  },
  "en": {
    "af_top_1": "Increase the resolution of anime with AI.",
    "af_top_2": "Let’s make <span class='nowrp'>high-resolution</span> Anime easily",
    "af_top_3": "Inquire about the<br>high-resolution anime",
    "af_top_4": "What's AnimeRefiner?",
    "af_top_5": "AnimeRefiner is an AI that can beautifully enhance animation resolution. <br class='hide-md-down'>Any animation can be 4x higher and 4x higher resolution.<br class='hide-md-down'>You can convert it into beautiful images as if they were made in that size.<br class='hide-md-down'>SD size (640x480px) anime can be converted to FHD size or larger, <br class='hide-md-down'>HD size (1280x) 720px) anime can be converted to 4K size or larger, and <br class='hide-md-down'>Full HD size (1920x1080px) anime can be converted to 8K size.<br class='hide-md-down'>Deep Learning is a unique technology that is incomparably more beautiful than previous technologies and can enhance animation resolution.",
    "af_top_6": "AnimeRefiner・Conversion Sample Video",
    "af_top_7": "Examples of AnimeRefiner",
    "af_top_8": "Features of AnimeRefiner",
    "af_top_9": "High-resolution by the state-of-the-art AI technology (Deep Learning)",
    "af_top_10": "AnimeRefiner uses Deep Learning to achieve high resolution in high quality that traditional image extension and interpolation techniques (up conversion technology)  have not enabled. By the unique AI model used in resolution enhance AI「<a href='https://cre8tiveai.com/pr'>Photo Refiner</a>」, you can higher the resolution of animation 4 times in both length and width. Having learned many features of low-resolution and high-resolution animations, AI can higher animation resolution incomparably more beautiful than previous technologies.",
    "af_top_11": "the original anime",
    "af_top_12": "previous (up conversion) technologies",
    "af_top_13": "AnimeRefiner",
    "af_top_14": "The edge is sharper and the thin outline is smooth, rather than the high resolution of existing technologies.",
    "af_top_15": "No need for a high-spec machine",
    "af_top_16": "In hardware, the high-performance GPU cluster and in softwear, patent-pending high-speed inference technology enabled high-speed inference. For example, in the CG field, rendering on their machines used to take time to obtain high quality images. But with AnimeRefiner, you can transform into a clean animation without using your own hardware.",
    "af_top_17": "comparison with existing technologies",
    "af_top_18": "Many conversion (upconversion) techniques extend the video to interpolate the middle. Therefore, the edges may be ambiguous or the noise may be stretched as it is. AnimeRefiner learns what high-definition images should be like, so you can create high-resolution images from low-resolution images.",
    "af_top_19": "1. Removal of various types of noise",
    "af_top_20": "2. AI estimates and generates sharp edges",
    "af_top_21": "3. Express the shadows beautifully",
    "af_top_22": "Save time and money for remastering and 4K and 8K animation production",
    "af_top_23": "4K remastering of past anime at low cost and speed",
    "af_top_24": "It takes a lot of time and money to produce an animated remaster. To increase the resolution, you have to  enlarging the size to re-draw the blurred part by hand and repair it. You need to cleanly repair each frame while eliminating video noise and more. With AnimeRefiner, AI will re-master high-resolution anime by conjecturing. It is possible to make a beautiful remaster version by removing noise from the animations. AI replaces more than 90% of the remaster work.",
    "af_top_pic1_a": "Past animation",
    "af_top_pic1_b": "AI remaster",
    "af_top_pic1_c": "High-resolution",
    "af_top_pic1_d": "Examples of eliminating noise",
    "af_top_25": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_1_en.jpg",
    "af_top_26": "Reduce the burden of creating 4K animation",
    "af_top_27": "With the spread of 4K TV, the need for 4K content has also increased. However, it takes more than twice  time to draw the original picture in a 4K size as in a full HD size. AnimeRefiner enables a new method of creating by up-converting the original HD-sized Key Frame to 4K. This allows you to produce high-quality anime such as 4K and 8K with lower cost and speed. The budget reduction effect for 4K and 8K animation production can be expected.",
    "af_top_pic2_a": "Produce in HD size",
    "af_top_pic2_b": "4K conversion",
    "af_top_28": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_2_en.jpg",
    "af_top_29": "About the anime \"Milky Panic -twelve-\"",
    "af_top_30": "Milky Panic -twelve- is an original animation from<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'>TOMASON</a> depicting the fight between the twelve zodiacs and the cat race with the western polemic touch and unique characters.",
    "af_top_31": "<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'>TOMASON</a> is an animation and CG studio that continues to develop a wide range of activities, start from the \"Hometown Reproduction Japanese Old Story\" series, it include VP, CM, planetarium programs, 3DCG modeling, and even video content scenarios to music, sound production, and editing. Inquiries about Milky Panic -twelve-, please click <a href='https://www.tomason.co.jp/contact' target='_blank'>here</a>.",
    "af_top_32": "Please note that videos uploaded to the site and YouTube have been converted to AnimeRefiner's high-resolution processing after down-converting the FHD video to make it easier to understand the effects of AnimeRefiner's high-resolution.",
    "af_top_33": "Non-High Definition Issues",
    "af_top_34": "We can also support 2-3 pulldowns and interlaces, which are problematic with anime and live-action videos. AnimeRefiner uses Deep Learning and Computer Vision to address these issues.",
    "af_top_35": "2-3 pulldown",
    "af_top_36": "A method of converting animation recorded at 24 frames per second into 30 frames per second of video signals used in television broadcasts and VODs.",
    "af_top_37": "deinterlacing",
    "af_top_38": "The process of converting interlaced video, such as a typical analog television signal or a 1080i format HDTV signal, to a non-interlaced format.",
    "af_top_39": "HIGH-RESOLUTION PROCESSING SEPARATING SUBTITLE",
    "af_top_40": "Subtitles are synthesized after the production of the animation, so there are cases where the subtitles and images are processed differently. Therefore, if you process it centrally, the subtitles may not be clean even if you can clean up the anime. Therefore, AnimeRefiner is able to process subtitles only, anime only with anime, and synthesize them later to achieve beautiful high resolution even in situations where subtitles and images are mixed.",
    "af_top_41": "Enables output in a variety of color modes",
    "af_top_42": "We have added a mode that allows you to produce multiple patterns of output, from a mode in which the original animation color is increased to a more attractive color using AI.",
    "af_top_43": "Multiple file formats (ingresses)",
    "af_top_44": "It supports various encoding including Apple ProRes 422, H264, and MPEG-4, so it is possible to enhance the quality of various file formats such as .mov, .mp4, and .mpeg.In addition, while the output is based on .mov, we can respond to your request.Please contact us for more information.",
    "af_top_45": "Achievements of introducing AnimeRefiner",
    "af_top_46": "Movie",
    "af_top_47": "Anime",
    "af_top_48": "“映画妖怪ウォッチ♪ ケータとオレっちの出会い編だニャン♪ワ、ワタクシも～♪♪”<br>©LEVEL-5/妖怪ウォッチ♪プロジェクト・テレビ東京",
    "af_top_49": "“劇場版 総集編 マジカパーティ”<br>©MAZICA PARTY PROJECT・TVO",
    "af_top_50": "“少年ハリウッド”<br>©NJA"
  },
  "ko": {
    "af_top_1": "애니메이션을 AI로 고해상도화하자.",
    "af_top_2": "Let’s make <span class='nowrp'>high-resolution</span> Anime easily",
    "af_top_3": "애니메이션의 고해상도화에<br>대해서 상담하다.",
    "af_top_4": "AnimeRefiner?",
    "af_top_5": "AnimeRefiner는 애니메이션을 깔끔하게 고해상도화할 수 있는 AI입니다. <brclass='hide-md-down'> 어떤 애니메이션이든 가로 4배, 세로 4배 고해상도화가 가능하며, <brclass='hide-md-down'> 마치 그 크기로 제작된 것 같은 아름다운 영상으로 변환이 가능합니다. <br class='hide-md-down'>SD사이즈(640 × 480 px)의 애니메이션이라면 FHD사이즈 이상,<br class='hide-md-down'>HD사이즈(1280 × 720px)의 애니메이션이라면 4 K사이즈 이상, <br class='hide-md-down'>풀 HD사이즈(1920 × 1080px)의 애니메이션이라면 8 K사이즈로 변환할 수 있습니다. <br class='hide-md-down'> Deep Learning을 이용한 자체 기술로 기존 기술과는 비교할 수 없을 정도로 아름답게 애니메이션을 고해상도화할 수 있습니다.",
    "af_top_6": "AnimeRefiner・변환샘플",
    "af_top_7": "고해상도화 예",
    "af_top_8": "AnimeRefiner의 특징",
    "af_top_9": "최첨단 AI 기술(Deep Learning)로 고해상도화",
    "af_top_10": "AnimeRefiner는 Deep Learning을 활용해, 종래의 화상을 길게 늘려 중간을 보간하는 변환(업컴버트) 기술로는 불가능했던, 고품질인 상태에서의 고해상도화가 가능합니다.고해상도화 AI'<a href='https://cre8tiveai.com/pr'>Photo Refiner</a>'로 기른 독자적인 AI모델로 애니메이션을 가로 세로 4배의 사이즈로 고해상도화할 수 있습니다.저해상도 동영상과 고해상도 동영상의 특징을 대량 학습한 AI는 기존 기술과는 비교가 안 될 정도로 아름답게 애니메이션을 고해상도화할 수 있습니다.",
    "af_top_11": "변환전의 애니메이션",
    "af_top_12": "기존 기술에서의 업 컨버트 예시",
    "af_top_13": "AnimeRefiner로 고해상도화",
    "af_top_14": "기존 기술로 고해상도화 하는 것보다 가장자리가 날렵하게 되어 있고 윤곽선이 매끄럽게 표현됩니다.",
    "af_top_15": "고스펙 머신 불필요",
    "af_top_16": "하드웨어면에서는고성능의 GPU클러스터를 채용하고 소프트웨어면에서도 특허 출원중의 고속 추론 기술에 의해 고속 추론을 실현했습니다. 예를 들어 CG분야에서는 고품질 이미지를 얻기 위해서 기존에는 시간을 들여 자사의 머신으로 렌더링을 할 필요가 있었습니다. AnimeRefiner를 사용하면, 자신의 하드웨어를 움직이는 일 없이, 예쁜고 유창한 애니메이션 영상으로 변환할 수 있습니다.",
    "af_top_17": "기존 기술과의 비교",
    "af_top_18": "많은 변환(업컴버트) 기술은 동영상을 길게 늘여 중간을 보간하고 있습니다. 그 때문에 에지가 애매하거나, 노이즈가 그대로 확대되거나 합니다. AnimeRefiner에서는, 고화질의 영상에 있어야 할 상태를 학습하고 있기 때문에, 저해상도의 영상으로부터 고해상도의 영상을 만들어 낼 수 있습니다.",
    "af_top_19": "1. 여러 종류의 노이즈 제거",
    "af_top_20": "2. AI가 추정해서 생성함으로써 가장자리를 날렵하게",
    "af_top_21": "3. 그늘도 예쁘게",
    "af_top_22": "리마스터링 및 4K·8K애니메이션의 시간과 비용을 절감",
    "af_top_23": "과거의 애니메이션을 저비용· 빠르게 4K리마스터링화",
    "af_top_24": "애니메이션 리마스터 제작에는 매우 막대한 시간과 비용이 소요됩니다. 고해상도화하고 싶은 사이즈까지 늘인 후, 희미해져 버린 부분을 손으로 다시 그려 수복합니다. 영상 노이즈 등을 제거하면서 1프레임씩 깔끔하게 복원할 필요가 있습니다. AnimeRefiner라면 AI가 고해상도 애니메이션을 추측해 리머스터링을 합니다. 영상안에 있는 노이즈나 흐림등을 제거하고, 아름다운 리마스터판을 만드는 것이 가능합니다. 90%이상의 리마스터링 작업을 AI가 대체합니다.",
    "af_top_pic1_a": "과거 애니메이션",
    "af_top_pic1_b": "AI 리마스터",
    "af_top_pic1_c": "고해상도화",
    "af_top_pic1_d": "노이즈 제거 예시",
    "af_top_25": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_1_ko.jpg",
    "af_top_26": "4K애니메이션 제작의 부담 경감",
    "af_top_27": "4K텔레비전의 보급에 따른 4K컨텐츠의 수요도 높아지고 있습니다. 그러나 풀 HD사이즈에서 원화를 그리는 것과 비교하면 4K사이즈에서 원화를 그리는 것이 2배 이상의 작업 시간을 필요로 합니다. AnimeRefiner를 사용하면 HD사이즈로 그린 원화를 4K에 업 컨버트 한다는 새로운 제작 방법을 실현할 수 있습니다. 이에 저비용과 고속도로 4K·8K 같은 고화질인 애니메이션을 제작할 수 있습니다. 4K·8K애니메이션 제작 예산 절감 효과가 기대됩니다.",
    "af_top_pic2_a": "HD사이즈로 제작",
    "af_top_pic2_b": "AI에 의해서 4K로",
    "af_top_28": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_2_ko.jpg",
    "af_top_29": "애니매이션 Milky Panic -twelve-의 관하여",
    "af_top_30": "Milky Panic -twelve-은 십이지동물들과 고양이족의 싸움을 <br class='hide-md-down'>서부극풍의 터치와 독특한 캐릭터로 그린 <a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'>TOMASON</a>의 오리지널 애니메이션입니다.",
    "af_top_31": "<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'> TOMASON</a>는 【고향재생 일본의 옛날 이야기】시리즈를 시작해 VP나 CM, 플라네타륨 프로그램, 3DCG모델링, 확충 영상 컨텐츠의 시나리오로부터 음악, 음향 제작, 편집까지, 다방면에 걸친 활동을 계속 전개하는 애니메이션&CG스튜디오입니다. Milky Panic -twelve-에 관한 문의는 <a href='https://www.tomason.co.jp/contact' target='_blank'>여기</a>로 부탁드립니다.",
    "af_top_32": "또한 사이트 및 Youtube에 업 로드하고 있는 동영상은  AnimeRefiner의 고해상도화의 효과를 알기 쉽게 하기 위해서, FHD의 영상을 다운 컨버트 한 후에 AnimeRefiner의 고해상도화의 처리를 하고 있습니다.",
    "af_top_33": "고해상도화 이외의 문제에도 대응",
    "af_top_34": "애니메이션이나 실사 영상으로 문제가 되는 2-3 풀다운에의 대응이나 인터레이스에의 대응도 실시할 수 있습니다. AnimeRefiner 에서는 Deep Learning (딥러닝) 나 Computer Vision(컴퓨터 비전) 등의 지견을 살려 이러한 문제에 대응할 수 있습니다.",
    "af_top_35": "2-3 풀다운",
    "af_top_36": "매초 24 컷으로 기록된 애니메이션을, 텔레비전 방송이나 VOD(비디오 온디멘드)등에서 이용되는 매초 30 프레임의 영상 신호로 변환하는 수법.",
    "af_top_37": "인터레이스 해제",
    "af_top_38": "일반적인 아날로그 TV 신호 또는 1080i 포맷의 HDTV 신호 등의 인터레이스 영상을 비인터레이스 형식으로 변환하는 과정.",
    "af_top_39": "자막과 영상을 분리한 고해상도화 처리",
    "af_top_40": "자막은 애니메이션을 제작한 후 합성되어 있기 때문에 자막과 영상에서 다르게 처리될 수 있습니다. 따라서 일차원적으로 처리하면 애니메이션을 예쁘게 만들 수 있지만 자막이 깔끔하지 않을 수 있습니다. 그래서 AnimeRefiner 에서는 자막은 자막만으로 처리하고 애니메이션은 애니메이션으로만 처리하여 나중에 합성함으로써 자막과 영상이 혼재하는 장면에 있어서도 예쁜 고해상도화를 실현할 수 있게 되었습니다.",
    "af_top_41": "다양한 색감으로 아웃풋 가능",
    "af_top_42": "원래의 애니메이션의 색감을 남긴 채 고해상도화하는 모드 부터, AI를 이용해 돋보이는 색감까지 여러 패턴으로 아웃풋 할 수 있는 모드를 추가했습니다.",
    "af_top_43": "다양한 파일 형식(입력) 지원 가능",
    "af_top_44": "Apple ProRes 422 나 H264, MPEG-4 를 시작으로 다양한 인코딩에 대응하고 있으므로, .mov나 .mp4, .mpeg 등의 여러가지 파일 형식을 고화질화하는 것이 가능합니다. 덧붙여 출력은 .mov를 기본으로 하면서, 요망에 따라 대응할 수 있습니다. 자세한 사항은 문의해 주십시오.",
    "af_top_45": "AnimeRefiner가 도입된 실적",
    "af_top_46": "영화",
    "af_top_47": "애니메이션",
    "af_top_48": "『映画妖怪ウォッチ♪ ケータとオレっちの出会い編だニャン♪ワ、ワタクシも～♪♪』<br>©LEVEL-5/妖怪ウォッチ♪プロジェクト・テレビ東京",
    "af_top_49": "『劇場版 総集編 マジカパーティ』<br>©MAZICA PARTY PROJECT・TVO",
    "af_top_50": "『少年ハリウッド』<br>©NJA"
  },
  "zh-cn": {
    "af_top_1": "用AI提升动画画质。",
    "af_top_2": "Let’s make <span class='nowrp'>high-resolution</span> Anime easily",
    "af_top_3": "咨询关于动画高清晰度化的问题",
    "af_top_4": "什么是AnimeRefiner？",
    "af_top_5": "AnimeRefiner是将动漫高清化的AI。<br class='hide-md-down'>任何动漫影像都能够以长4倍、宽4倍的倍率高清化，<br class='hide-md-down'>就像原来就是以这个清晰度制作出来的一样。如果是<br class='hide-md-down'>SD尺寸(640×480px)的动漫则可以转换为FHD以上，如果是<br class='hide-md-down'>HD尺寸(1280×720px）则可以转换为4K以上，如果是<br class='hide-md-down'>全HD（1920×1080px）的动画的话，则可以变换为8K尺寸。我们采用<br class='hide-md-down'>Deep learning进行自主开发，与现有技术相比可大大提升动漫影响的清晰度，且非常流畅。",
    "af_top_6": "AnimeRefiner・转变样本动画",
    "af_top_7": "高清化例子",
    "af_top_8": "AnimeRefiner的特长",
    "af_top_9": "使用最先进的AI技术（Deep Learning）的高清化",
    "af_top_10": "AnimeRefiner通过活用 Deep Learning，实现了以往拉伸现有图像并补插中间部分的变换(up-convert技术)无法实现的高品质状态。利用高分辨率化人工智能「<a href='https://cre8tiveai.com/pr'>Photo Refiner</a>」培养的独立人工智能模型，动画规格可以提高至16倍（长4倍 × 宽4倍）。大量学习低分辨率视频和高分辨率视频特征的AI转换出的高画质动画，其清晰美丽程度无法与现有技术相提并论。",
    "af_top_11": "转换前的动画",
    "af_top_12": "以现有技术进行的高清化（up-convert技术）",
    "af_top_13": "使用AnimeRefiner的高清化",
    "af_top_14": "比起现有技术，使用AnimeRefiner的高清动画的边缘更加鲜明，轮廓线更加纤细，整体非常精致。",
    "af_top_15": "不需要高性能电脑",
    "af_top_16": "在硬件方面使用了高性能的GPU群，在软件方面也通过专利申请中的快速推论技术，整体上实现了高速的推论。比如说在 CG领域，为了获得高品质的图像以往需要花大量时间用个人或公司的高性能机器进行渲染工作。使用 AnimeRefiner，你完全不需要任何高性能的硬件或软件，就算是你的手提电脑也可以将动画变换成动感十足，流畅清晰的高清动画。",
    "af_top_17": "与现有技术的比较",
    "af_top_18": "很多转换技术（上转换）可以拉长视频并在中间进行补充。因此，许多动画放大时会有边缘模糊或噪点直接被放大等现象。AnimeRefiner中学习高画质影像中应该存在的状态，所以可以从低分辨率的影像中制作高分辨率的影像。",
    "af_top_19": "1.去除各种类型的噪点",
    "af_top_20": "2.通过人工智能的推测和生成使边缘变得清晰",
    "af_top_21": "3. 阴影表现也非常鲜明",
    "af_top_22": "节省重新录制及制作4K/8K动画的时间和费用",
    "af_top_23": "低廉高速地将过去的动画重新录制成4K动画",
    "af_top_24": "制作动画的重制版需要大量的时间和费用。通常我们把动画时长拉长到想要达到的高清化程度，然后重新画出所有模糊的部分来进行修复。如果消除影像的噪点，需要专业人员来进行每1帧的修复。AnimeRefiner却可以通过AI会推测出动画的高分辨预想，以达到重新录制的效果。它可以去除影像中的噪点或模糊等，制作出精致的重制版。在整个过程中，90%以上的重新录制工作由AI代替。",
    "af_top_pic1_a": "过去的动画例子",
    "af_top_pic1_b": "AI重制",
    "af_top_pic1_c": "高清化",
    "af_top_pic1_d": "降噪例",
    "af_top_25": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_1_zh-cn.jpg",
    "af_top_26": "4K动画制作过程的减负",
    "af_top_27": "随着4K高清电视的普及，图片映像的4K需求也在增加。但是与以全高清尺寸来画原画相比，以 4K高清尺寸画原画需要其2倍以上的工作时间。使用AnimeRefiner，可实现【HD高清原画升级为4K】的崭新制作方法。这样就可以通过更低的费用，更快的速度制作出4K，甚至8K等超高画质动画。",
    "af_top_pic2_a": "以HD高清尺寸作画",
    "af_top_pic2_b": "AI将其转换为4K尺寸",
    "af_top_28": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_2_zh-cn.jpg",
    "af_top_29": "关于动画Milky Panic -twelve-",
    "af_top_30": "Milky Panic -twelve-是把12支动物和猫族之间的战争,<br class='hide-md-down'>以西部剧风和独特的卡通人物组成的<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'> TOMASON</a>的原创动画。",
    "af_top_31": "<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'> TOMASON</a>由【故乡再生 日本的老故事】系列开始，在VP，CM，Planetaryum 3DCG Modeling甚至是视频剧本到音乐，音响制作和编辑等多方面展开活动的动画&CG摄影棚。关于Milky Panic -twelve-的咨询请点击<a href='https://www.tomason.co.jp/contact' target='_blank'>这里</a>。",
    "af_top_32": "为了便于顾客了解AnimeRefiner的高分辨率化效果，上传至网站和Youtube的视频经过了下变频转换至FHD影像后实施AnimeRefiner高清化处理。",
    "af_top_33": "关于高分辨率化以外的问题",
    "af_top_34": "在动画或实拍影像中成为问题的 2-3 下拉式应对（pulldown）或隔断式应对（interlace）。在AnimeRefiner中 Deep Learning（深层学习） 以及Computer Vision（计算机视觉应用）等知识来应对这些问题。",
    "af_top_35": "2-3 pulldown",
    "af_top_36": "该技术是将以每秒24帧记录的动画转换为用于电视广播或VOD(视频播放)等的每秒30帧的影像信号。",
    "af_top_37": "间歇性关闭",
    "af_top_38": "将隔行扫描的图像 (例如，典型的模拟电视信号或 1080i格式的 HDTV信号 )转换成非隔行扫描的图像的过程。",
    "af_top_39": "字幕与影像分离的高分辨率化处理",
    "af_top_40": "字幕是在制作动画后合成的，因此字幕和影像中会有不同的处理。如果进行统一处理的话，虽然影像会变漂亮，但是字幕有可能会模糊不清。AnimeRefiner中，字幕作为字幕进行处理，影像仅作为影像进行处理，处理之后进行合成。因此在字幕和影像混杂在一起的动画也能实现真正的高清化。",
    "af_top_41": "多种色调模式",
    "af_top_42": "从保留原有动画色彩的高分辨率化模式到使用AI展现的色彩，追加了多模式外装的模式。",
    "af_top_43": "可对应各种文件形式（输入）",
    "af_top_44": "我们可以对应Apple ProRes 422、H264、MPEG-4等多种编码，所以可高画质化.mov、.mp4、.mpeg等各种文件形式。另外，输出以.mov为基方式，可按照顾客要求个别对应。详情请咨询我们。",
    "af_top_45": "引入Anime Refiner的成就",
    "af_top_46": "电影",
    "af_top_47": "日本动画片",
    "af_top_48": "『映画妖怪ウォッチ♪ ケータとオレっちの出会い編だニャン♪ワ、ワタクシも～♪♪』<br>©LEVEL-5/妖怪ウォッチ♪プロジェクト・テレビ東京",
    "af_top_49": "『劇場版 総集編 マジカパーティ』<br>©MAZICA PARTY PROJECT・TVO",
    "af_top_50": "『少年ハリウッド』<br>©NJA"
  },
  "zh-tw": {
    "af_top_1": "用AI提升動畫畫質。",
    "af_top_2": "Let’s make <span class='nowrp'>high-resolution</span> Anime easily",
    "af_top_3": "咨詢關於動畫高清晰度化的問題",
    "af_top_4": "什麽是AnimeRefiner？",
    "af_top_5": "AnimeRefiner是將動漫高清化的AI。<br class='hide-md-down'>任何動漫影像都能夠以長4倍、寬4倍的倍率高清化，<br class='hide-md-down'>就像原來就是以這個清晰度製作出來的一樣。如果是<br class='hide-md-down'>SD尺寸(640×480px)的動漫則可以轉換爲FHD以上，如果是<br class='hide-md-down'>HD尺寸(1280×720px）則可以轉換爲4K以上，如果是<br class='hide-md-down'>全HD（1920×1080px）的動畫的話，則可以變換爲8K尺寸。我們採用<br class='hide-md-down'>Deep Learning進行自主開發，與現有技術相比可大大提升動漫影響的清晰度，且非常流暢。",
    "af_top_6": "AnimeRefiner・轉變樣本動畫",
    "af_top_7": "高清化例子",
    "af_top_8": "AnimeRefiner的特長",
    "af_top_9": "使用最先進的AI技術（Deep Learning）的高清化",
    "af_top_10": "AnimeRefiner通過活用 Deep Learning，實現了以往拉伸現有圖像並補插中間部分的變換(up-convert技術)無法實現的高品質狀態。利用高分辨率化人工智能「<a href='https://cre8tiveai.com/pr'>Photo Refiner</a>」培養的獨立人工智能模型，動畫規格可以提高至16倍（長4倍 × 寬4倍）。大量學習低分辨率視頻和高分辨率視頻特征的AI轉換出的高畫質動畫，其清晰美麗程度無法與現有技術相提並論。",
    "af_top_11": "轉換前的動畫",
    "af_top_12": "以現有技術進行的高清化（up-convert技術）",
    "af_top_13": "使用AnimeRefiner的高清化",
    "af_top_14": "比起現有技術，使用AnimeRefiner的高清動畫的邊緣更加鮮明，輪廓線更加纖細，整體非常精致。",
    "af_top_15": "不需要高性能電腦",
    "af_top_16": "在硬件方面使用了高性能的GPU群，在軟件方面也通過專利申請中的快速推論技術，整體上實現了高速的推論。比如說在 CG領域，為了獲得高品質的圖像以往需要花大量時間用個人或公司的高性能機器進行渲染工作。使用 AnimeRefiner，妳完全不需要任何高性能的硬件或軟件，就算是妳的手提電腦也可以將動畫變換成動感十足，流暢清晰的高清動畫。",
    "af_top_17": "與現有技術的比較",
    "af_top_18": "很多轉換技術（上轉換）可以拉長視頻並在中間進行補充。因此，許多動畫放大時會有邊緣模糊或噪點直接被放大等現象。AnimeRefiner中學習高畫質影像中應該存在的狀態，所以可以從低分辨率的影像中製作高分辨率的影像。",
    "af_top_19": "1.去除各種類型的噪點",
    "af_top_20": "2.通過人工智能的推測和生成使邊緣變得清晰",
    "af_top_21": "3. 陰影表現也非常鮮明",
    "af_top_22": "節省重新錄制及制作4K/8K動畫的時間和費用",
    "af_top_23": "低廉高速地將過去的動畫重新錄制成4K動畫",
    "af_top_24": "制作動畫的重制版需要大量的時間和費用。通常我們把動畫時長拉長到想要達到的高清化程度，然後重新畫出所有模糊的部分來進行修復。如果消除影像的噪點，需要專業人員來進行每1幀的修復。AnimeRefiner卻可以通過AI會推測出動畫的高分辨預想，以達到重新錄制的效果。它可以去除影像中的噪點或模糊等，制作出精致的重制版。在整個過程中，90%以上的重新錄制工作由AI代替。",
    "af_top_pic1_a": "過去的動畫例子",
    "af_top_pic1_b": "AI重制",
    "af_top_pic1_c": "高清化",
    "af_top_pic1_d": "降噪例",
    "af_top_25": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_1_zh-tw.jpg",
    "af_top_26": "4K動畫制作過程的減負",
    "af_top_27": "隨著4K高清電視的普及，圖片映像的4K需求也在增加。但是與以全高清尺寸來畫原畫相比，以 4K高清尺寸畫原畫需要其2倍以上的工作時間。使用AnimeRefiner，可實現【HD高清原畫升級為4K】的嶄新制作方法。這樣就可以通過更低的費用，更快的速度制作出4K，甚至8K等超高畫質動畫。",
    "af_top_pic2_a": "以HD高清尺寸作畫",
    "af_top_pic2_b": "AI將其轉換為4K尺寸",
    "af_top_28": "https://storage.googleapis.com/anime_refiner_public_files/01_top/4_solutions/solutions_pics_i18n/solutions_pic_2_zh-tw.jpg",
    "af_top_29": "關於動畫Milky Panic -twelve-",
    "af_top_30": "Milky Panic -twelve-是把12支動物和貓族之間的戰爭,<br class='hide-md-down'>以西部劇風和獨特的卡通人物組成的<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'>TOMASON</a>的原創動畫。",
    "af_top_31": "<a class='no-underline' href='https://www.tomason.co.jp/' target='_blank'> TOMASON</a>由【故鄉再生 日本的老故事】系列開始，在VP，CM，Planetaryum 3DCG Modeling甚至是視頻劇本到音樂，音響製作和編輯等多方面展開活動的動畫&CG攝影棚。關於Milky Panic -twelve-的諮詢請點擊<a href='https://www.tomason.co.jp/contact' target='_blank'>這裡</a>。",
    "af_top_32": "爲了便於顧客瞭解AnimeRefiner的高分辨率化效果，上傳至網站和Youtube的視頻經過了下變頻轉換至FHD影像後實施AnimeRefiner高清化處理。",
    "af_top_33": "關於高分辨率化以外的問題",
    "af_top_34": "在動畫或實拍影像中成爲問題的 2-3 下拉式應對（pulldown）或隔斷式應對（interlace）。在AnimeRefiner中 Deep Learning（深層學習） 以及Computer Vision（計算機視覺應用）等知識來應對這些問題。",
    "af_top_35": "2-3 pulldown",
    "af_top_36": "該技術是將以每秒24幀記錄的動畫轉換爲用於電視廣播或VOD(視頻播放)等的每秒30幀的影像信號。",
    "af_top_37": "間歇性關閉",
    "af_top_38": "將隔行掃描的圖像 (例如，典型的模擬電視信號或 1080i格式的 HDTV信號 )轉換成非隔行掃描的圖像的過程。",
    "af_top_39": "字幕與影像分離的高分辨率化處理",
    "af_top_40": "字幕是在製作動畫後合成的，因此字幕和影像中會有不同的處理。如果進行統一處理的話，雖然影像會變漂亮，但是字幕有可能會模糊不清。AnimeRefiner中，字幕作爲字幕進行處理，影像僅作爲影像進行處理，處理之後進行合成。因此在字幕和影像混雜在一起的動畫也能實現真正的高清化。",
    "af_top_41": "多種色調模式",
    "af_top_42": "從保留原有動畫色彩的高分辨率化模式到使用AI展現的色彩，追加了多模式外裝的模式。",
    "af_top_43": "可對應各種文件形式（輸入）",
    "af_top_44": "我們可以對應Apple ProRes 422、H264、MPEG-4等多種編碼，所以可高畫質化.mov、.mp4、.mpeg等各種文件形式。另外，輸出以.mov爲基方式，可按照顧客要求個別對應。詳情請諮詢我們。",
    "af_top_45": "引入Anime Refiner的成就",
    "af_top_46": "電影",
    "af_top_47": "日本動畫片",
    "af_top_48": "『映画妖怪ウォッチ♪ ケータとオレっちの出会い編だニャン♪ワ、ワタクシも～♪♪』<br>©LEVEL-5/妖怪ウォッチ♪プロジェクト・テレビ東京",
    "af_top_49": "『劇場版 総集編 マジカパーティ』<br>©MAZICA PARTY PROJECT・TVO",
    "af_top_50": "『少年ハリウッド』<br>©NJA"
  }
}
</i18n>
