import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt, faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store/index'
import { } from './requests'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

library.add(faExternalLinkAlt, faImage)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    // このeventをhookにprerenderingが行われる (npm run build-prod or build-stage 実行時)
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
