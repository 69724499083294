<template>
  <div id="footer" v-show="isFooterVisible">
    <v-container class="menu_container mb-5">
      <v-row>
        <v-col class="mt-12" md="4" sm="6" cols="12">
          <p class="menu_title">運営会社について</p>
          <ul>
            <li>
              <a href="https://www.radius5.co.jp/" target="_blank">
                {{ $t("af_footer_1") }}
              </a>
            </li>
            <li>
              <a href="https://cre8tiveai.com/" target="_blank">
                cre8tiveAI
              </a>
            </li>
            <li>
              <a href="http://ooh-ai.mystrikingly.com/" target="_blank">OOH AI</a>
            </li>
            <li>
              <a href="https://www.facebook.com/radiusfive/" target="_blank">
                {{ $t("af_footer_2") }}
              </a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
    <hr />
    <div class="copy_right py-2">
      <p>© 2020 RADIUS5 Inc.</p>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
export default {
  name: "AppFooter",
  data() {
    const localeDict = {
      ja: "日本語",
      en: "English",
      fr: "Français",
      es: "Español",
      pt: "português",
      de: "Deutsch",
      nl: "Nederlands",
      tr: "Türkçe",
      "zh-cn": "简体中文",
      "zh-tw": "繁體中文",
      sv: "Svenska",
      it: "Italiano",
      ko: "한국어",
      ar: "العربية",
      th: "ภาษาไทย",
      id: "Bahasa Indonesia",
      pl: "Polski",
      vi: "Tiếng Việt",
      hi: "தமிழ்",
      ru: "Русский",
      bn: "বাংলা"
    };
    let invertDict = {};
    Object.keys(localeDict).forEach(k => {
      invertDict[localeDict[k]] = k;
    });
    return {
      isFooterVisible: this.$store.state.screen.isHeaderVisible,
      curLang: localeDict[this.$i18n.locale],
      localeDict: localeDict,
      invertDict: invertDict,
      langsL: [
        localeDict["ja"],
        localeDict["es"],
        localeDict["ar"],
        localeDict["ru"],
        localeDict["ko"],
        localeDict["it"],
        localeDict["id"],
        localeDict["pl"],
        localeDict["vi"],
        localeDict["th"],
        localeDict["bn"]
      ],
      langsR: [
        localeDict["zh-cn"],
        localeDict["en"],
        localeDict["pt"],
        localeDict["de"],
        localeDict["fr"],
        localeDict["nl"],
        localeDict["tr"],
        localeDict["zh-tw"],
        localeDict["hi"],
        localeDict["sv"]
      ]
    };
  },
  computed: {
    isHeaderVisible() {
      return this.$store.state.screen.isHeaderVisible;
    },
    ...mapGetters("user", ["logined"])
  },
  methods: {
    changeLang(lang) {
      const code = this.invertDict[lang];
      if (code) {
        this.curLang = lang;
        localStorage.setItem("locale", code);
        Cookies.set("locale", code, {
          domain: "animerefiner.com",
          expires: 365
        });
        this.$i18n.locale = code;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

#footer {
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #f5f5f5;
  color: #757575;
  z-index: 100;

  .logo_container {
    overflow: hidden;
    background-color: #FFF8E1;
    width: 100%;

    .logo {
      display: flex;
      justify-content: center;
    }

    .logo-image {
      width: 100%;
      max-width: 256px;
    }
  }

  .menu_container {
    overflow: hidden;
    margin: auto;
    max-width: 1040px;

    .menu_title {
      color: #616161;
      text-align: left;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    ul {
      padding-left: 0px;
      margin-bottom: 0px;
    }
    li {
      font-size: 14px;
      list-style: none;
      text-align: left;
      line-height: 28px;
      font-weight: 500;

      a {
        color: #212121;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  hr {
    display: block;
    width: 100%;
    border: 0;
    height: 1px;
    background-color: #c5c5c5;
  }

  .copy_right {
    p {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #757575;
      margin: 14px auto 8px;
    }

    img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 1px;
      &.twitter {
        right: 240px;
      }
      &.facebook {
        right: 180px;
      }
    }
  }
}
</style>

<i18n>
{
  "ja": {
    "af_footer_1": "運営会社について",
    "af_footer_2": "公式Facebookページ"
  },
  "en": {
    "af_footer_1": "about us",
    "af_footer_2": "official Facebook page"
  },
  "ko": {
    "af_footer_1": "우리의 관하여",
    "af_footer_2": "공식 페이스북 페이지"
  },
  "zh-cn": {
    "af_footer_1": "关于我们",
    "af_footer_2": "官方facebook网址"
  },
  "zh-tw": {
    "af_footer_1": "關於我們",
    "af_footer_2": "官方facebook網址"
  }
}
</i18n>
