import Vue from 'vue';
import Vuex from 'vuex';

import screen from './screen'
import user from './user'
import preview from './preview'
require('@/assets/scss/common.scss')

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    screen,
    user,
    preview,
  }
})