const initialState = {
  stayingServicePath: null, // ユーザが今滞在しているサービスのpath
}

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {

  },
  mutations: {
    setStayingServicePath(state, servicePath) { // servicePath: /, /sr など
      state.stayingServicePath = servicePath
    },
  },
  actions: {

  },
}