// 対応言語は 以下のサブドメイン + TDL(英語)
const SUBDOMAIN_LIST = ['ja', 'ko', 'zh-cn', 'zh-tw']

// 前提として、言語とサブドメインは1:1に固定している。(ja.animerefiner.comから返るHTMLは日本語でprerenderingされたHTML)
// サーバサイドのAPIがStripeからの戻りURLを指定するのもAPIエンドポイントのサブドメインによって決定するため、
// APIエンドポイントはHTMLを取得したサブドメインと揃える
function apiURLBySubdomain() {
  // NOTE. envについて: https://cli.vuejs.org/guide/mode-and-env.html#modes
  let url = process.env.VUE_APP_API_BASE_URL.replace(/{}/, '')
  for (const subdomain of SUBDOMAIN_LIST) {
    if (location.hostname.startsWith(subdomain + '.')) {
      url = process.env.VUE_APP_API_BASE_URL.replace(/{}/, `${subdomain}.`)
      break
    }
  }
  return url
}

const API_BASE_URL = apiURLBySubdomain()
const FRONT_BASE_URL_TEMPLATE = process.env.VUE_APP_FRONT_BASE_URL_TEMPLATE

export {
  API_BASE_URL, FRONT_BASE_URL_TEMPLATE
}