<template>
  <div
    id="header"
    class="shd-m"
    v-show="isHeaderVisible"
  >
    <div class="header-container">
      <!-- タイトルロゴ -->
      <router-link to="/">
        <img class="nav-logo hide-sm-down" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/0_ogp_favicon/navbar_logo.svg" alt="AnimeRefiner">
        <img class="nav-logo hide-sm-up" src="https://storage.googleapis.com/anime_refiner_public_files/01_top/0_ogp_favicon/logo_icon.svg" alt="AnimeRefiner">
      </router-link>

      <div class="right-container">
        <v-btn rounded outlined class="inquiry-btn" color="#FF8538" href="#g_form">
          <span class="inquiry-btn-text text-none">{{ $t("af_header_2") }}</span>
          <span class="tryangle"></span>
        </v-btn>
        
        <!-- 言語選択ボタン -->
        <!-- リリース時点では日本語のみなのでコメントアウト -->
        <v-menu offset-y left class="lang-container">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="grey darken-1"
              dark
              outlined
              rounded
              class="lang-btn"
            >
              <v-icon class="mr-1">mdi-earth</v-icon>
              <v-flex class="lang-now display-pc mr-2 text-none">{{ curLang }}</v-flex>
              <v-flex class="lang-now display-sp">{{ $i18n.locale }}</v-flex>
            </v-btn>
          </template>

          <v-card class="langs-card">
            <v-container class="langs-container">
              <h4 class="h4-lang">{{ $t("af_header_1") }}</h4>
              <v-layout row wrap>
                <v-flex xs6>
                  <a
                    class="lang-link"
                    :href="frontURLByLang(invertDict[lang])"
                    v-for="(lang, index) in langsL"
                    :key="index"
                    >
                    <v-list-item class="lang">{{ lang }}</v-list-item>
                  </a>
                </v-flex>
                <v-flex xs6>
                  <a
                    class="lang-link"
                    :href="frontURLByLang(invertDict[lang])"
                    v-for="(lang, index) in langsR"
                    :key="index"
                    >
                    <v-list-item class="lang">{{ lang }}</v-list-item>
                  </a>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import {frontBaseURLByLang} from "../util"
export default {
  name: "AppHeader",
  data() {
    const localeDict = {
      ja: "日本語",
      en: "English",
      ko: "한국어",
      "zh-cn": "简体中文",
      "zh-tw": "繁體中文"
    };
    let invertDict = {};
    Object.keys(localeDict).forEach(k => {
      invertDict[localeDict[k]] = k;
    });
    return {
      curLang: localeDict[this.$i18n.locale],
      localeDict: localeDict,
      invertDict: invertDict,
      pathName: window.location.pathname,
      drawer: null,
      langsL: [
        localeDict["ja"],
        localeDict["ko"]
      ],
      langsR: [
        localeDict["en"],
        localeDict["zh-cn"],
        localeDict["zh-tw"]
      ]
    };
  },
  computed: {
    isHeaderVisible() {
      return this.$store.state.screen.isHeaderVisible;
    },
    stayingServicePath() {
      return this.$store.state.user.stayingServicePath || "/";
    }
  },
  methods: {
    frontURLByLang(lang) {
      return frontBaseURLByLang(lang) + this.$route.path
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

// ヘッダー内のv-menu
.v-menu__content {
  border-radius: 4px;
}
#header {
  background-color: white;
  z-index: 200;
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;

  @include mq-up {
    position: sticky;
  }

  .header-container {
    margin: auto;
    max-width: 1440px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .nav-logo {
      height: 47px;
      margin-left: 32px;

      @include mq-down {
        height: 36px;
        margin-left: 16px;
      }
    }

    .right-container {
      margin-right: 16px;
      display: flex;
      align-items: center;
      height: 100%;

      .inquiry-btn {
        font-size: 16px;
        margin-right: 32px;
        
        @include mq-down {
          font-size: 13px;

          padding: 0 10px;
          min-height: initial;
          height: 30px;
          margin-right: 16px;
        }

        .inquiry-btn-text {
          color: #707070;
        }
      }

      .tryangle {
        margin-left: 8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 7px;
        border-color: transparent transparent transparent #ff8438;

      }
      
      // 言語選択ボタン
      .lang-container {
        text-align: right;
        .lang-btn {
          // max-width: 160px;
          height: 32px;
          padding: 0px 16px;
          .lang-now {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

// 言語選択の中身
.langs-card {
  @include mq-up {
    width: 376px;
  }
  @include mq-down {
    width: 70vw;
  }

  .langs-container {
    @include mq-up {
      padding: 24px 16px;
    }
    @include mq-down {
      padding: 8px 4px;
    }

    .h4-lang {
      font-size: 16px;
      color: #c2185b;
      font-weight: 500;
      text-align: center;
    }
    
    .lang-link {
      color: #424242;
      text-align: left;
      text-decoration: none;
  
      &:hover {
        text-decoration: none;
  
        .lang {
          background-color: #F5F5F5;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "ja": {
    "af_header_1": "言語を選択",
    "af_header_2": "問い合わせる"
  },
  "en": {
    "af_header_1": "Select Language",
    "af_header_2": "Inquire"
  },
  "ko": {
    "af_header_1": "언어 선택",
    "af_header_2": "우리에게 문의"
  },
  "zh-cn": {
    "af_header_1": "用AI提升动画画质。",
    "af_header_2": "咨询我们"
  },
  "zh-tw": {
    "af_header_1": "用AI提升動畫畫質。",
    "af_header_2": "諮詢我們"
  }
}
</i18n>