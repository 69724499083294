import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import store from './store/index'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/')
        next()
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  window.scroll(0, 0)
  /*global gtag*/
  if (gtag) {
    gtag('event', 'screen_view', { 'screen_name': to.name })
  }
  next()
})

export default router