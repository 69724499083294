import Cookies from 'js-cookie'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// NOTE. サブドメインからlocaleを判定し、ログイン等Auth0でホストしているページ用にcookieに保存
function judgeLocale() {
  const candidates = ['ja', 'en', 'zh-cn', 'zh-tw', 'ko']
  let judgedLocale = 'en'
  candidates.forEach(c => {
    if (location.hostname.startsWith(c)) {
      judgedLocale = c
    }
  })
  // prerendering処理時はvue.config.jsでsetしたlangに強制的に差し替え
  if (window['langForPrerendering']) {
    judgedLocale = window['langForPrerendering']
  }

  // loginページでの言語判定に使う (サブドメインにもcookieで共有したい)
  Cookies.set('locale', judgedLocale, { domain: 'animerefiner.com', expires: 365 })
  return judgedLocale
}

export default new VueI18n({
  locale: judgeLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
})
