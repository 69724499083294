// 画面の表示コンポーネント状態を保持する
const initialState = {
  isHeaderVisible: true,
  isFooterVisible: true,
  showError: false,
  error: null,
  errorMessage: null,
  errorLocalizeId: null
}

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  geters: {

  },
  mutations: {
    headerVisible(state, flag) {
      state.isHeaderVisible = flag
    },
    footerVisible(state, flag) {
      state.isFooterVisible = flag
    },
    showError(state, error) {
      state.showError = true
      state.error = error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('error.response:', error.response)
        state.errorMessage = `Status: ${error.response.status}, data: ${JSON.stringify(error.response.data)}`
        if (error.response.data.localize_id) {
          state.errorLocalizeId = error.response.data.localize_id
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('error.request:', error.request)
        state.errorMessage = `Status text: ${error.request.statusText}`
      } else {
        console.log('error:', error)
        if (error.localizeId) {
          state.errorLocalizeId = error.localizeId
        } else {
          state.errorMessage = `Unknown Error: ${error.message}`
        }
      }
    },
    hideError(state) {
      state.showError = false
      state.error = null
    }
  },
  actions: {

  },
}