<template>
  <div id="app">
    <v-app>
      <app-header />
      <router-view />
      <app-footer />
      <!-- 汎用エラーモーダル -->
      <v-dialog v-model="showError" persistent width="350">
        <v-card>
          <v-card-title class="title lighten-2 px-5" primary-title>{{ $t("err_1") }}</v-card-title>
          <v-card-text>
            <span v-html="errorLocalizeId ? $t(errorLocalizeId) : errorMessage"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="routeToNext" class="mx-3 my-2">{{ $t("err_2")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  name: "app",
  components: {
    AppHeader,
    AppFooter
  },

  data() {
    return {
      // v-dialogは v-modelとして必ずdataを指定しないといけない？ので、
      // 回りくどいが store.state.screen.showErrorをwatchしてshowErrorに反映させる
      showError: false
    };
  },
  computed: {
    errorMessage() {
      return this.$store.state.screen.errorMessage;
    },
    errorLocalizeId() {
      return this.$store.state.screen.errorLocalizeId;
    },
    storeShowError() {
      return this.$store.state.screen.showError;
    }
  },
  methods: {
    routeToNext() {
      location.href = `${location.protocol}//${location.host}/`;
    }
  },
  watch: {
    storeShowError(newFlg) {
      this.showError = newFlg;
      if (newFlg) {
        /*global gtag*/
        gtag("event", "exception", {
          description: this.errorMessage
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

<i18n>
{
  "ja": {
    "err_1": "エラー",
    "err_2": "トップに戻る",
    "err_10": "現在ご加入のプランでは、1000x1000のサイズまでしか対応しておりません。 より大きな画像を試したい方は2500x2500対応のプロプランをご検討ください。更に大きなサイズが必要な方はお問い合せください",
    "err_11": "現在ご加入のプランでは、2500x2500のサイズまでしか対応しておりません。 より大きな画像を試したい方はお問い合せください",
    "err_20": "メールの認証が完了していません。メールをご確認の上、ログインを行ってください。"
  },
  "en": {
    "err_1": "Error",
    "err_2": "Back to top",
    "err_10": "Your current plan supports until 1000x1000 image size. Please consider pro plan for bigger images.",
    "err_11": "Your current plan supports until 2500x2500 image size. Please inquire for bigger images.",
    "err_20": "Email address authentication is not complete.Please check your email and log in."
  },
  "fr": {
    "err_1": "Une erreur s’est produite",
    "err_2": "Revenir à la page d'accueil",
    "err_10": "Vous pouvez uploader des images jusqu’à 1000 x 1000 px avec votre plan actuel. \nUtilisez Plan Pro pour uploader des images de 2500 x 2500 px. \nPour des images plus grandes, veuillez nous contacter.",
    "err_11": "Vous pouvez uploader des images jusqu’a 2500 x 2500 px avec votre plan actuel. \nPour des images plus grandes, veuillez nous contacter.",
    "err_20": "Votre inscription n’est pas encore terminée.  Veuillez consulter votre e-mail de souscription afin de finaliser votre inscription."
  },
  "es": {
    "err_1": "Error",
    "err_2": "Volver arriba",
    "err_10": "Su plan actual soporta hasta 1000x1000 tamaño de la imagen. Por favor considerar el plan Pro para imágenes más grandes.",
    "err_11": "Su plan actual soporta hasta 2500x2500 tamaño de la imagen. Por favor, pregunte por las imágenes más grandes.",
    "err_20": "autenticación de dirección de correo electrónico no es complete.Please consultar su correo electrónico e ingrese."
  },
  "pt": {
    "err_1": "Erro",
    "err_2": "Voltar ao topo",
    "err_10": "Seu plano atual pode ser aplicado até uma imagem de tamanho 1000 × 1000. Para uma imagem maior, verifique o Pro plan que permite até 2500 × 2500. Se precisar de mais, entre em contato conosco",
    "err_11": "Seu plano atual pode ser aplicado até uma imagem de tamanho 2500 × 2500. Se você quiser experimentar uma imagem maior, entre em contato conosco",
    "err_20": "Sua autenticação de email não foi concluída. Verifique seu e-mail e faça o login novamente."
  },
  "de": {
    "err_1": "Fehler",
    "err_2": "Zur Startseite",
    "err_10": "Ihr derzeitig aktiver Abo-Plan unterstützt nur Größen bis zu 1000x1000.\nWenn Sie ein größeres Bild ausprobieren möchten, ziehen Sie bitte einen professionellen Plan für 2500x2500 in Betracht. Bitte kontaktieren Sie uns, falls Sie ein höheres Format benötigen.",
    "err_11": "Ihr derzeitig aktiver Abo-Plan unterstützt nur Größen bis zu 2500x2500.\nBitte kontaktieren Sie uns, falls Sie ein höheres Format benötigen.",
    "err_20": "Die E-Mail-Authentifizierung ist nicht vollständig.\nBitte überprüfen Sie Ihre E-Mail und melden Sie sich an."
  },
  "nl": {
    "err_1": "Fout",
    "err_2": "Terug naar boven",
    "err_10": "Uw huidige abonnement ondersteunt tot 1000x1000 beeldformaat. Kunt u overwegen pro plan voor grotere afbeeldingen.",
    "err_11": "Uw huidige abonnement ondersteunt tot 2500x2500 beeldformaat. Informeer voor grotere afbeeldingen.",
    "err_20": "E-mail adres verificatie is niet complete.Please uw e-mail en log in."
  },
  "tr": {
    "err_1": "Hata",
    "err_2": "Başa dönüş",
    "err_10": "Mevcut planınız 1000x1000 görüntü boyutu kadar destekler. Daha büyük resimler için pro planını düşünün.",
    "err_11": "Mevcut planınız 2500x2500 görüntü boyutu kadar destekler. Daha büyük resimler için bilgi alınız.",
    "err_20": "E-posta adresi doğrulama e-postanızı kontrol ve giriş complete.Please değildir."
  },
  "zh-cn": {
    "err_1": "错误",
    "err_2": "回到主页",
    "err_10": "您现在加入的方案只有1000x1000的尺寸，如果需要更大尺寸2500x2500的话，请加入专业方案。还需要比2500x2500更大的尺寸，请联系我们",
    "err_11": "您现在加入的方案最大只能够2500x2500，如果需要更大的尺寸，请联系我们",
    "err_20": "您的邮件还没有验证，请查看您的邮箱以后再次登入"
  },
  "zh-tw": {
    "err_1": "錯誤",
    "err_2": "回到主頁",
    "err_10": "您現在加入的方案只有1000x1000的尺寸，如果需要更大尺寸2500x2500的話，請加入專業方案。還需要比2500x2500更大的尺寸，請聯繫我們",
    "err_11": "您現在加入的方案最大只能夠2500x2500，如果需要更大的尺寸，請聯繫我們",
    "err_20": "您的郵件還沒有驗證，請查看您的郵箱以後再次登入"
  },
  "sv": {
    "err_1": "Fel",
    "err_2": "Tillbaka till toppen",
    "err_10": "Din nuvarande plan stöd fram till 1000x1000 bildstorleken. Vänligen överväga pro plan för större bilder.",
    "err_11": "Din nuvarande plan stöd fram till 2500x2500 bildstorleken. Kontakta oss för större bilder.",
    "err_20": "E-postadress autentisering är inte complete.Please kontrollera din e-post och logga in."
  },
  "it": {
    "err_1": "Errore",
    "err_2": "Tornare al Top.",
    "err_10": "Con il plan attuale che hai sottoscritto, supporta solo fino alla dimensione di 1.000 pixel x 1.000 pixel. Se vuoi provare con dimensioni più grandi, si prega di considerare Pro Plan che supporta fino alla dimensione di 2.500 pixel x 2.500 pixel. Se serve più grande, si prega di contattarci.",
    "err_11": "Con il plan attuale che hai sottoscritto, supporta solo fino alla dimensione di 2.500 pixel x 2.500 pixel. Se vuoi provare con un'immagine più grande, si prega di contattarci.",
    "err_20": "L'autenticazione email non è completa. Controlla la tua email e fai il login."
  },
  "ko": {
    "err_1": "에러",
    "err_2": "홈화면으로 돌아가기",
    "err_10": "현재 가입 플랜에서는, 1000x1000의 사이즈까지만 대응하고 있습니다.  보다 큰 이미지를 사용하고 싶은 분은 2500 x2500 대응의 프로 플랜을 검토해 주십시오. 한층 더 큰 사이즈가 필요하신 분은 문의해 주세요",
    "err_11": "현재 가입의 플랜에서는, 2500x2500의 사이즈까지만 대응하고 있습니다.  보다 큰 화상을 사용하고 싶으신 분은 문의해 주세요",
    "err_20": "이메일 인증이 완료되지 않았습니다. 이메일을 확인하신 후, 로그인을 실시해 주세요."
  },
  "ar": {
    "err_1": "خطأ",
    "err_2": "الرجوع الى أعلى الصفحة",
    "err_10": "تدعم خطتك الحالية حتى 1000x1000 حجم الصورة. يرجى النظر في خطة الموالية للصور أكبر.",
    "err_11": "تدعم خطتك الحالية حتى 2500x2500 حجم الصورة. يرجى الاستفسار عن الصور أكبر.",
    "err_20": "المصادقة عنوان البريد الإلكتروني ليست complete.Please تحقق من بريدك الالكتروني وتسجيل الدخول."
  },
  "th": {
    "err_1": "เกิดความผิดพลาด",
    "err_2": "กลับสู่ด้านบน",
    "err_10": "planที่คุณใช้งานอยู่ รองรับขนาดสูงสุดอยู่ที่ 1,000x1,000 เท่านั้น หากต้องการขนาดที่ใหญ่ขึ้น กรุณาใช้ Pro plan ที่รองรับขนาด 2,500x2,500 กรุณาติดต่อเรา หากคุณต้องการขนาดที่ใหญ่ขึ้น",
    "err_11": "planที่คุณใช้งานอยู่ รองรับขนาดสูงสุดอยู่ที่ 2,500x2,500 เท่านั้น กรุณาติดต่อเรา หากคุณต้องการขนาดที่ใหญ่ขึ้น",
    "err_20": "ยืนยันอีเมล์ไม่สมบูรณ์ กรุณาตรวจสอบอีเมล์แล้วเข้าสู่ระบบ"
  },
  "id": {
    "err_1": "Kesalahan",
    "err_2": "Kembali ke atas",
    "err_10": "Rencana Anda saat ini mendukung hingga 1000x1000 ukuran gambar. Silakan mempertimbangkan rencana pro untuk gambar yang lebih besar.",
    "err_11": "Rencana Anda saat ini mendukung hingga 2500x2500 ukuran gambar. Silahkan bertanya untuk gambar yang lebih besar.",
    "err_20": "Email otentikasi alamat tidak complete.Please memeriksa email Anda dan log in."
  },
  "pl": {
    "err_1": "Błąd",
    "err_2": "Powrót do góry",
    "err_10": "Twój obecny plan obsługuje aż 1000x1000 wielkość wyświetlanego obrazu. Proszę zwrócić uwagę, pro plan dla większych obrazów.",
    "err_11": "Twój obecny plan obsługuje aż 2500x2500 wielkość wyświetlanego obrazu. Proszę pytać o większych obrazów.",
    "err_20": "Uwierzytelnianie adres e-mail nie jest complete.Please sprawdzić pocztę i zalogować się."
  },
  "vi": {
    "err_1": "lỗi",
    "err_2": "Trở lại đầu trang",
    "err_10": "kế hoạch hiện tại của bạn hỗ trợ cho đến 1000x1000 kích thước hình ảnh. Vui lòng xem xét kế hoạch ủng hộ cho hình ảnh lớn hơn.",
    "err_11": "kế hoạch hiện tại của bạn hỗ trợ cho đến 2500x2500 kích thước hình ảnh. Hãy tìm hiểu cho hình ảnh lớn hơn.",
    "err_20": "xác thực địa chỉ email là không complete.Please kiểm tra email của bạn và đăng nhập."
  },
  "hi": {
    "err_1": "त्रुटि",
    "err_2": "वापस शीर्ष पर",
    "err_10": "आपकी वर्तमान योजना जब तक 1000x1000 छवि आकार का समर्थन करता है। कृपया बड़ा छवियों के लिए समर्थक योजना पर विचार करें।",
    "err_11": "आपकी वर्तमान योजना जब तक 2500x2500 छवि आकार का समर्थन करता है। बड़ा छवियों के लिए पूछताछ करें।",
    "err_20": "ई-मेल पता प्रमाणीकरण complete.Please नहीं अपने ईमेल की जाँच और लॉग इन है।"
  },
  "ru": {
    "err_1": "ошибка",
    "err_2": "Вернуться к началу",
    "err_10": "Ваш текущий план не поддерживает до 1000x1000 размера изображения. Пожалуйста, обратите внимание про план больших изображений.",
    "err_11": "Ваш текущий план не поддерживает до 2500x2500 размера изображения. Пожалуйста, задавайте вопросы для больших изображений.",
    "err_20": "Аутентификация Адрес электронной почты не complete.Please проверить свою электронную почту и войти."
  },
  "bn": {
    "err_1": "ত্রুটি",
    "err_2": "উপরে ফিরে যাও",
    "err_10": "আপনার বর্তমান প্ল্যান পর্যন্ত 1000x1000 ছবির আকার সমর্থন করে। দয়া করে বড় ইমেজ জন্য প্রো পরিকল্পনা বিবেচনা।",
    "err_11": "আপনার বর্তমান প্ল্যান পর্যন্ত 2500x2500 ছবির আকার সমর্থন করে। বড় ইমেজ জন্য জিজ্ঞাসা করুন।",
    "err_20": "ই-মেইল ঠিকানা প্রমাণীকরণ complete.Please না আপনার ইমেল চেক এবং লগ ইন করুন।"
  }
}
</i18n>
